<template>
  <div id="widget-edit-container" style="width: 100%; height: 100%">
    <div id="top-bar">
      <div style="flex: 1; font-weight: bold">
        {{ settings.title.title || "未命名组件" }}
      </div>
      <el-button type="default" size="mini" @click="handleCancel"
        >取消</el-button
      >
      <el-button type="primary" size="mini" @click="handleSave">完成</el-button>
    </div>
    <div :style="'display: flex; height: ' + (height - 40) + 'px'">
      <div id="attr">
        <div class="tabs">
          <div class="tabs-header">
            <div
              :class="
                activeWidgetTypeTab == 'basic'
                  ? 'tabs-header-item tabs-header-item-active'
                  : 'tabs-header-item'
              "
              @click="() => (activeWidgetTypeTab = 'basic')"
            >
              基础组件
            </div>
            <div
              :class="
                activeWidgetTypeTab == 'advance'
                  ? 'tabs-header-item tabs-header-item-active'
                  : 'tabs-header-item'
              "
              @click="() => (activeWidgetTypeTab = 'advance')"
            >
              高级组件
            </div>
          </div>
          <div class="tabs-body" :style="'height: 155px;'">
            <div
              class="tabs-panel"
              :style="activeWidgetTypeTab == 'basic' ? '' : 'display: none;'"
            >
              <div class="chart-types-container">
                <div class="chart-types">
                  <div
                    :class="
                      'chart-type ' +
                      (type == 'text' ? 'chart-type-selected' : '')
                    "
                    @click="handleSelectType('text')"
                    @mouseover="handleMouseOverType('text')"
                    @mouseleave="handleMouseLeaveType('text')"
                  >
                    <img
                      :src="require('@/assets/image/dashboard/widget/text.png')"
                      style="width: 27px; height: 27px"
                    />
                  </div>
                  <div
                    :class="
                      'chart-type ' +
                      (type == 'image' ? 'chart-type-selected' : '')
                    "
                    @click="handleSelectType('image')"
                    @mouseover="handleMouseOverType('image')"
                    @mouseleave="handleMouseLeaveType('image')"
                  >
                    <img
                      :src="
                        require('@/assets/image/dashboard/widget/image.png')
                      "
                      style="width: 27px; height: 27px"
                    />
                  </div>
                  <div
                    :class="
                      'chart-type ' +
                      (type == 'video' ? 'chart-type-selected' : '')
                    "
                    @click="handleSelectType('video')"
                    @mouseover="handleMouseOverType('video')"
                    @mouseleave="handleMouseLeaveType('video')"
                  >
                    <img
                      :src="
                        require('@/assets/image/dashboard/widget/video.png')
                      "
                      style="width: 36px; height: 36px"
                    />
                  </div>
                  <div
                    :class="
                      'chart-type ' +
                      (type == 'web' ? 'chart-type-selected' : '')
                    "
                    @click="handleSelectType('web')"
                    @mouseover="handleMouseOverType('web')"
                    @mouseleave="handleMouseLeaveType('web')"
                  >
                    <img
                      :src="require('@/assets/image/dashboard/widget/web.png')"
                      style="width: 28px; height: 28px"
                    />
                  </div>
                  <div
                    :class="
                      'chart-type ' +
                      (type == 'pie' ? 'chart-type-selected' : '')
                    "
                    @click="handleSelectType('pie')"
                    @mouseover="handleMouseOverType('pie')"
                    @mouseleave="handleMouseLeaveType('pie')"
                  >
                    <img
                      :src="require('@/assets/image/dashboard/widget/pie.png')"
                    />
                  </div>
                  <div
                    :class="
                      'chart-type ' +
                      (type == 'ring' ? 'chart-type-selected' : '')
                    "
                    @click="handleSelectType('ring')"
                    @mouseover="handleMouseOverType('ring')"
                    @mouseleave="handleMouseLeaveType('ring')"
                  >
                    <img
                      :src="require('@/assets/image/dashboard/widget/ring.png')"
                    />
                  </div>
                  <div
                    :class="
                      'chart-type ' +
                      (type == 'line' ? 'chart-type-selected' : '')
                    "
                    @click="handleSelectType('line')"
                    @mouseover="handleMouseOverType('line')"
                    @mouseleave="handleMouseLeaveType('line')"
                  >
                    <img
                      :src="
                        require('@/assets/image/dashboard/widget/line_stack.png')
                      "
                    />
                  </div>
                  <div
                    :class="
                      'chart-type ' +
                      (type == 'area' ? 'chart-type-selected' : '')
                    "
                    @click="handleSelectType('area')"
                    @mouseover="handleMouseOverType('area')"
                    @mouseleave="handleMouseLeaveType('area')"
                  >
                    <img
                      :src="
                        require('@/assets/image/dashboard/widget/area_stack.png')
                      "
                    />
                  </div>
                  <div
                    :class="
                      'chart-type ' +
                      (type == 'funnel' ? 'chart-type-selected' : '')
                    "
                    @click="handleSelectType('funnel')"
                    @mouseover="handleMouseOverType('funnel')"
                    @mouseleave="handleMouseLeaveType('funnel')"
                  >
                    <img
                      :src="
                        require('@/assets/image/dashboard/widget/funnel.png')
                      "
                    />
                  </div>
                  <div
                    :class="
                      'chart-type ' +
                      (type == 'histogram' ? 'chart-type-selected' : '')
                    "
                    @click="handleSelectType('histogram')"
                    @mouseover="handleMouseOverType('histogram')"
                    @mouseleave="handleMouseLeaveType('histogram')"
                  >
                    <img
                      :src="
                        require('@/assets/image/dashboard/widget/histogram_stack.png')
                      "
                    />
                  </div>
                  <div
                    :class="
                      'chart-type ' +
                      (type == 'bar' ? 'chart-type-selected' : '')
                    "
                    @click="handleSelectType('bar')"
                    @mouseover="handleMouseOverType('bar')"
                    @mouseleave="handleMouseLeaveType('bar')"
                  >
                    <img
                      :src="
                        require('@/assets/image/dashboard/widget/bar_stack.png')
                      "
                    />
                  </div>
                  <div
                    :class="
                      'chart-type ' +
                      (type == 'scatter' ? 'chart-type-selected' : '')
                    "
                    @click="handleSelectType('scatter')"
                    @mouseover="handleMouseOverType('scatter')"
                    @mouseleave="handleMouseLeaveType('scatter')"
                  >
                    <img
                      :src="
                        require('@/assets/image/dashboard/widget/scatter_stack.png')
                      "
                    />
                  </div>
                  <div
                    :class="
                      'chart-type ' +
                      (type == 'wordcloud' ? 'chart-type-selected' : '')
                    "
                    @click="handleSelectType('wordcloud')"
                    @mouseover="handleMouseOverType('wordcloud')"
                    @mouseleave="handleMouseLeaveType('wordcloud')"
                  >
                    <img
                      :src="
                        require('@/assets/image/dashboard/widget/wordcloud.png')
                      "
                    />
                  </div>
                  <div
                    :class="
                      'chart-type ' +
                      (type == 'map' ? 'chart-type-selected' : '')
                    "
                    @click="handleSelectType('map')"
                    @mouseover="handleMouseOverType('map')"
                    @mouseleave="handleMouseLeaveType('map')"
                  >
                    <img
                      :src="require('@/assets/image/dashboard/widget/map.png')"
                    />
                  </div>
                  <!--<div :class="'chart-type ' + (type == 'kpi' ? 'chart-type-selected' : '')" @click="handleSelectType('kpi')" @mouseover="handleMouseOverType('kpi')"  @mouseleave="handleMouseLeaveType('kpi')">
                    <img :src="require('@/assets/image/dashboard/widget/kpi.png')" style="width: 34px; height: 34px;" />
                  </div>-->
                  <div
                    :class="
                      'chart-type ' +
                      (type == 'rank' ? 'chart-type-selected' : '')
                    "
                    @click="handleSelectType('rank')"
                    @mouseover="handleMouseOverType('rank')"
                    @mouseleave="handleMouseLeaveType('rank')"
                  >
                    <img
                      :src="require('@/assets/image/dashboard/widget/rank.png')"
                    />
                  </div>
                  <div
                    :class="
                      'chart-type ' +
                      (type == 'select' ? 'chart-type-selected' : '')
                    "
                    @click="handleSelectType('select')"
                    @mouseover="handleMouseOverType('select')"
                    @mouseleave="handleMouseLeaveType('select')"
                  >
                    <img
                      :src="
                        require('@/assets/image/dashboard/widget/select.png')
                      "
                    />
                  </div>
                </div>
              </div>
              <div class="chart-type-tip">{{ typeTip }}</div>
            </div>
            <div
              class="tabs-panel"
              :style="activeWidgetTypeTab == 'advance' ? '' : 'display: none;'"
            >
              <div class="chart-types-container">
                <div class="chart-types">
                  <div
                    :class="
                      'chart-type ' +
                      (type == 'datav' ? 'chart-type-selected' : '')
                    "
                    @click="handleSelectType('datav')"
                    @mouseover="handleMouseOverType('datav')"
                    @mouseleave="handleMouseLeaveType('datav')"
                  >
                    <img
                      :src="
                        require('@/assets/image/dashboard/widget/datav.png')
                      "
                      style="width: 24px; height: 24px"
                    />
                  </div>
                  <div
                    :class="
                      'chart-type ' +
                      (type == 'echarts' ? 'chart-type-selected' : '')
                    "
                    @click="handleSelectType('echarts')"
                    @mouseover="handleMouseOverType('echarts')"
                    @mouseleave="handleMouseLeaveType('echarts')"
                  >
                    <img
                      :src="
                        require('@/assets/image/dashboard/widget/echarts.png')
                      "
                      style="width: 28px; height: 28px"
                    />
                  </div>
                </div>
              </div>
              <div class="chart-type-tip">{{ typeTip }}</div>
            </div>
          </div>
        </div>
        <div class="tabs">
          <div class="tabs-header">
            <div
              :class="
                activeSettingTab == 'common'
                  ? 'tabs-header-item tabs-header-item-active'
                  : 'tabs-header-item'
              "
              @click="() => (activeSettingTab = 'common')"
            >
              通用设置
            </div>
            <div
              :class="
                activeSettingTab == 'widget'
                  ? 'tabs-header-item tabs-header-item-active'
                  : 'tabs-header-item'
              "
              @click="() => (activeSettingTab = 'widget')"
            >
              组件设置
            </div>
          </div>
          <div class="tabs-body" :style="'height:' + (height - 276) + 'px;'">
            <div
              class="tabs-panel"
              :style="activeSettingTab == 'common' ? '' : 'display: none;'"
            >
              <el-collapse>
                <el-collapse-item title="名称" name="name">
                  <template slot="title">
                    <div class="collapse-title">名称</div>
                  </template>
                  <div class="collapse-content">
                    <el-form
                      label-position="top"
                      label-width="80px"
                      size="mini"
                    >
                      <el-form-item label="名称">
                        <el-input
                          v-model="settings.name"
                          placeholder="自定义组件名称"
                        />
                      </el-form-item>
                    </el-form>
                  </div>
                </el-collapse-item>
                <el-collapse-item title="标题" name="title">
                  <template slot="title">
                    <div class="collapse-title">标题</div>
                  </template>
                  <div class="collapse-content">
                    <el-form
                      label-position="top"
                      label-width="80px"
                      size="mini"
                    >
                      <el-form-item label="标题">
                        <el-input v-model="settings.title.title" />
                      </el-form-item>
                      <el-form-item label="颜色">
                        <el-color-picker
                          v-model="settings.title.color"
                          @change="handleSettingTitleChange"
                        ></el-color-picker>
                      </el-form-item>
                      <el-form-item label="字体大小">
                        <el-input-number
                          v-model="settings.title.fontSize"
                          controls-position="right"
                          :min="10"
                          :max="100"
                          @change="handleSettingTitleSizeChange"
                        />
                      </el-form-item>
                      <el-form-item label="字体加粗">
                        <el-switch v-model="settings.title.bold" />
                      </el-form-item>
                      <el-form-item label="对齐">
                        <el-select v-model="settings.title.align">
                          <el-option label="左对齐" value="left"></el-option>
                          <el-option label="区中" value="center"></el-option>
                          <el-option label="右对齐" value="right"></el-option>
                        </el-select>
                      </el-form-item>
                      <el-form-item label="分割线">
                        <el-switch v-model="settings.title.showSplitLine" />
                      </el-form-item>
                      <template v-if="settings.title.showSplitLine">
                      <el-form-item label="分割线样式">
                        <el-select v-model="settings.title.splitLineStyle">
                          <el-option label="实线" value="solid"></el-option>
                          <el-option label="虚线" value="dashed"></el-option>
                        </el-select>
                      </el-form-item>
                      <el-form-item label="分割线大小">
                        <el-input-number
                          v-model="settings.title.splitLineSize"
                          controls-position="right"
                          :min="1"
                          :max="10"
                          @change="handleSettingChange"
                        />
                      </el-form-item>
                      <el-form-item label="分割线颜色">
                        <el-color-picker
                          v-model="settings.title.splitLineColor"
                          @change="handleSettingChange"
                        ></el-color-picker>
                      </el-form-item>
                      <el-form-item label="分割线距离">
                        <el-input-number
                          v-model="settings.title.splitLineMargin"
                          controls-position="right"
                          :min="0"
                          :max="100"
                          @change="handleSettingChange"
                        />
                      </el-form-item>
                      </template>
                      <el-form-item label="隐藏">
                        <el-switch v-model="settings.title.hidden" />
                      </el-form-item>
                    </el-form>
                  </div>
                </el-collapse-item>
                <el-collapse-item title="文本" name="text">
                  <template slot="title">
                    <div class="collapse-title">文本</div>
                  </template>
                  <div class="collapse-content">
                    <el-form
                      label-position="top"
                      label-width="80px"
                      size="mini"
                    >
                      <el-form-item label="颜色">
                        <el-color-picker
                          v-model="settings.text.color"
                          @change="handleSettingTextChange"
                        ></el-color-picker>
                      </el-form-item>
                      <el-form-item label="字体大小">
                        <el-input-number
                          v-model="settings.text.fontSize"
                          controls-position="right"
                          :min="10"
                          :max="100"
                          @change="handleSettingTextChange"
                        />
                      </el-form-item>
                      <el-form-item label="字体加粗">
                        <el-switch v-model="settings.text.bold" />
                      </el-form-item>
                      <el-form-item label="行高">
                        <el-input-number
                          v-model="settings.text.lineHeight"
                          controls-position="right"
                          :min="10"
                          :max="100"
                          @change="handleSettingTextChange"
                        />
                      </el-form-item>
                      <el-form-item
                        v-if="
                          type == 'text' || type == 'kpi' || type == 'select'
                        "
                        label="横向对齐"
                      >
                        <el-select v-model="settings.text.align">
                          <el-option label="左对齐" value="left"></el-option>
                          <el-option label="区中" value="center"></el-option>
                          <el-option label="右对齐" value="right"></el-option>
                        </el-select>
                      </el-form-item>
                      <el-form-item
                        v-if="
                          type == 'text' || type == 'kpi' || type == 'select'
                        "
                        label="竖向对齐"
                      >
                        <el-select v-model="settings.text.valign">
                          <el-option label="顶部对齐" value="top"></el-option>
                          <el-option label="区中" value="middle"></el-option>
                          <el-option
                            label="底部对齐"
                            value="bottom"
                          ></el-option>
                        </el-select>
                      </el-form-item>
                      <el-form-item label="悬浮窗提示">
                        <el-switch v-model="settings.text.showTip" />
                      </el-form-item>
                    </el-form>
                  </div>
                </el-collapse-item>
                <el-collapse-item title="边框" name="border">
                  <template slot="title">
                    <div class="collapse-title">边框</div>
                  </template>
                  <div class="collapse-content">
                    <el-form
                      label-position="top"
                      label-width="80px"
                      size="mini"
                    >
                      <el-form-item label="模板">
                        <el-select
                          v-model="selectedBorderTemplate"
                          @change="handleSelectBorderTemplate"
                        >
                          <el-option label="边框1" value="border1"></el-option>
                        </el-select>
                      </el-form-item>
                      <el-form-item label="颜色">
                        <el-color-picker
                          v-model="settings.border.color"
                          @change="handleSettingSizeChange"
                          show-alpha
                        ></el-color-picker>
                      </el-form-item>
                      <el-form-item label="左宽">
                        <el-input
                          v-model="settings.border.left"
                          @change="handleSettingSizeChange"
                        />
                      </el-form-item>
                      <el-form-item label="右宽">
                        <el-input
                          v-model="settings.border.right"
                          @change="handleSettingSizeChange"
                        />
                      </el-form-item>
                      <el-form-item label="上宽">
                        <el-input
                          v-model="settings.border.top"
                          @change="handleSettingSizeChange"
                        />
                      </el-form-item>
                      <el-form-item label="下宽">
                        <el-input
                          v-model="settings.border.bottom"
                          @change="handleSettingSizeChange"
                        />
                      </el-form-item>
                      <el-form-item label="圆角">
                        <el-input
                          v-model="settings.border.radius"
                          @change="handleSettingSizeChange"
                        />
                      </el-form-item>
                      <el-form-item label="图片">
                        <file-upload
                          v-model="settings.border.image"
                          type="form"
                          title="边框图片"
                          placeholder="边框图片"
                          accept="image/png,image/jpeg"
                        />
                      </el-form-item>
                      <el-form-item label="图片裁剪左">
                        <el-input
                          v-model="settings.border.imageSliceLeft"
                          @change="handleSettingSizeChange"
                        />
                      </el-form-item>
                      <el-form-item label="图片裁剪右">
                        <el-input
                          v-model="settings.border.imageSliceRight"
                          @change="handleSettingSizeChange"
                        />
                      </el-form-item>
                      <el-form-item label="图片裁剪上">
                        <el-input
                          v-model="settings.border.imageSliceTop"
                          @change="handleSettingSizeChange"
                        />
                      </el-form-item>
                      <el-form-item label="图片裁剪下">
                        <el-input
                          v-model="settings.border.imageSliceBottom"
                          @change="handleSettingSizeChange"
                        />
                      </el-form-item>
                      <el-form-item label="图片重复方式">
                        <el-select v-model="settings.border.imageRepeat">
                          <el-option label="拉伸" value="stretch"></el-option>
                          <el-option label="平铺" value="round"></el-option>
                          <el-option label="重复" value="repeat"></el-option>
                        </el-select>
                      </el-form-item>
                    </el-form>
                  </div>
                </el-collapse-item>
                <el-collapse-item title="边距" name="padding">
                  <template slot="title">
                    <div class="collapse-title">边距</div>
                  </template>
                  <div class="collapse-content">
                    <el-form
                      label-position="top"
                      label-width="80px"
                      size="mini"
                    >
                      <el-form-item label="左边距">
                        <el-input-number
                          v-model="settings.padding.left"
                          controls-position="right"
                          :min="0"
                          @change="handleSettingSizeChange"
                        />
                      </el-form-item>
                      <el-form-item label="右边距">
                        <el-input-number
                          v-model="settings.padding.right"
                          controls-position="right"
                          :min="0"
                          @change="handleSettingSizeChange"
                        />
                      </el-form-item>
                      <el-form-item label="上边距">
                        <el-input-number
                          v-model="settings.padding.top"
                          controls-position="right"
                          :min="0"
                          @change="handleSettingSizeChange"
                        />
                      </el-form-item>
                      <el-form-item label="下边距">
                        <el-input-number
                          v-model="settings.padding.bottom"
                          controls-position="right"
                          :min="0"
                          @change="handleSettingSizeChange"
                        />
                      </el-form-item>
                    </el-form>
                  </div>
                </el-collapse-item>
                <el-collapse-item title="背景" name="background">
                  <template slot="title">
                    <div class="collapse-title">背景</div>
                  </template>
                  <div class="collapse-content">
                    <el-form
                      label-position="top"
                      label-width="80px"
                      size="mini"
                    >
                      <el-form-item label="背景颜色">
                        <el-color-picker
                          v-model="settings.background.color"
                          show-alpha
                        ></el-color-picker>
                      </el-form-item>
                      <el-form-item label="背景图片">
                        <file-upload
                          v-model="settings.background.image"
                          type="form"
                          title="背景图片"
                          placeholder="背景图片"
                          accept="image/png,image/jpeg"
                        />
                      </el-form-item>
                      <el-form-item label="背景图片缩放">
                        <el-select v-model="settings.background.imageScale">
                          <el-option label="不缩放" value=""></el-option>
                          <el-option label="自适应" value="auto"></el-option>
                          <el-option
                            label="等比例缩放"
                            value="cover"
                          ></el-option>
                        </el-select>
                      </el-form-item>
                      <el-form-item label="暗黑预览">
                        <el-switch
                          v-model="settings.background.darkPreview"
                        ></el-switch>
                      </el-form-item>
                    </el-form>
                  </div>
                </el-collapse-item>
                <el-collapse-item title="滚动条" name="scrollbar">
                  <template slot="title">
                    <div class="collapse-title">滚动条</div>
                  </template>
                  <div class="collapse-content">
                    <el-form
                      label-position="top"
                      label-width="80px"
                      size="mini"
                    >
                      <el-form-item label="隐藏">
                        <el-switch v-model="settings.scrollbar.hidden" />
                      </el-form-item>
                    </el-form>
                  </div>
                </el-collapse-item>
                <el-collapse-item title="事件" name="event">
                  <template slot="title">
                    <div class="collapse-title">事件</div>
                  </template>
                  <div class="collapse-content">
                    <el-form
                      label-position="top"
                      label-width="80px"
                      size="mini"
                    >
                      <el-form-item label="响应鼠标事件">
                        <el-switch v-model="settings.event.pointerEvent" />
                      </el-form-item>
                      <el-form-item label="事件前缀">
                        <el-input v-model="settings.event.prefix" />
                      </el-form-item>
                      <el-form-item label="鼠标经过显示组件">
                        <el-input v-model="settings.event.popoverUrl" />
                      </el-form-item>
                      <el-form-item label="点击跳转地址">
                        <el-input v-model="settings.event.naviUrl" />
                      </el-form-item>
                      <el-form-item label="点击跳转方式">
                        <el-select v-model="settings.event.naviType">
                          <el-option
                            label="重定向"
                            value="redirect"
                          ></el-option>
                          <el-option
                            label="新窗口"
                            value="newwindow"
                          ></el-option>
                          <el-option label="对话框" value="dialog"></el-option>
                        </el-select>
                      </el-form-item>
                      <el-form-item
                        v-if="settings.event.naviType == 'dialog'"
                        label="对话框宽度"
                      >
                        <el-input
                          v-model="settings.dialog.width"
                          placeholder="像素或百分比"
                          @change="handleSettingChange"
                        />
                      </el-form-item>
                      <el-form-item
                        v-if="settings.event.naviType == 'dialog'"
                        label="对话框高度"
                      >
                        <el-input
                          v-model="settings.dialog.height"
                          placeholder="像素或百分比"
                          @change="handleSettingChange"
                        />
                      </el-form-item>
                      <el-form-item
                        v-if="settings.event.naviType == 'dialog'"
                        label="对话框边框颜色"
                      >
                        <el-color-picker
                          v-model="settings.dialog.borderColor"
                          @change="handleSettingChange"
                          show-alpha
                        ></el-color-picker>
                      </el-form-item>
                      <el-form-item
                        v-if="settings.event.naviType == 'dialog'"
                        label="对话框边框大小"
                      >
                        <el-input
                          v-model="settings.dialog.borderWidth"
                          @change="handleSettingChange"
                        />
                      </el-form-item>
                      <el-form-item
                        v-if="settings.event.naviType == 'dialog'"
                        label="对话框圆角"
                      >
                        <el-input
                          v-model="settings.dialog.borderRadius"
                          @change="handleSettingChange"
                        />
                      </el-form-item>
                      <el-form-item
                        v-if="settings.event.naviType == 'dialog'"
                        label="对话框背景色"
                      >
                        <el-color-picker
                          v-model="settings.dialog.backgroundColor"
                          @change="handleSettingChange"
                          show-alpha
                        ></el-color-picker>
                      </el-form-item>
                      <el-form-item
                        v-if="settings.event.naviType == 'dialog'"
                        label="对话框遮罩层背景色"
                      >
                        <el-color-picker
                          v-model="settings.dialog.maskBackgroundColor"
                          @change="handleSettingChange"
                          show-alpha
                        ></el-color-picker>
                      </el-form-item>
                    </el-form>
                  </div>
                </el-collapse-item>
                <el-collapse-item title="显示" name="display">
                  <template slot="title">
                    <div class="collapse-title">显示</div>
                  </template>
                  <div class="collapse-content">
                    <el-form
                      label-position="top"
                      label-width="80px"
                      size="mini"
                    >
                      <el-form-item label="隐藏">
                        <el-switch v-model="settings.display.hidden" />
                      </el-form-item>
                    </el-form>
                  </div>
                </el-collapse-item>
              </el-collapse>
            </div>
            <div
              class="tabs-panel"
              :style="activeSettingTab == 'widget' ? '' : 'display: none;'"
            >
              <el-collapse v-if="type">
                <el-collapse-item
                  :style="
                    type == 'text' || type == 'kpi' || type == 'select'
                      ? ''
                      : 'display: none;'
                  "
                  title="前缀"
                  name="prefix"
                >
                  <template slot="title">
                    <div class="collapse-title">前缀</div>
                  </template>
                  <div class="collapse-content">
                    <el-form
                      label-position="top"
                      label-width="80px"
                      size="mini"
                    >
                      <el-form-item v-if="type == 'text'" label="方向">
                        <el-select
                          v-model="settings.prefix.direction"
                          @change="handleSettingChange"
                        >
                          <el-option
                            label="横向"
                            value="horizontal"
                          ></el-option>
                          <el-option label="纵向" value="vertical"></el-option>
                        </el-select>
                      </el-form-item>
                      <el-form-item label="文本">
                        <el-input
                          v-model="settings.prefix.text"
                          placeholder="前缀文本"
                          @change="handleSettingTextChange"
                        />
                      </el-form-item>
                      <el-form-item label="文本颜色">
                        <el-color-picker
                          v-model="settings.prefix.textColor"
                          @change="handleSettingTextChange"
                        ></el-color-picker>
                      </el-form-item>
                      <el-form-item label="字体大小">
                        <el-input-number
                          v-model="settings.prefix.fontSize"
                          controls-position="right"
                          :min="10"
                          :max="100"
                          @change="handleSettingTextChange"
                        />
                      </el-form-item>
                      <el-form-item label="字体加粗">
                        <el-switch v-model="settings.prefix.bold" />
                      </el-form-item>
                      <el-form-item label="文本边距">
                        <el-input-number
                          v-model="settings.prefix.textMargin"
                          controls-position="right"
                          :min="0"
                          :max="30"
                          @change="handleSettingTextChange"
                        />
                      </el-form-item>
                      <el-form-item label="图标">
                        <el-popover
                          placement="bottom-start"
                          width="630"
                          trigger="click"
                          @show="$refs['iconSelect'].reset()"
                        >
                          <icon-select
                            ref="iconSelect"
                            @selected="handleSelectPrefixIcon"
                          />
                          <el-input
                            slot="reference"
                            v-model="settings.prefix.icon"
                            placeholder="点击选择图标"
                            readonly
                            clearable
                          >
                            <i
                              v-if="
                                settings.prefix.icon &&
                                settings.prefix.icon.indexOf('el-icon-') === 0
                              "
                              slot="prefix"
                              :class="'el-icon ' + settings.prefix.icon"
                              style="font-size: 1.2em"
                            />
                            <i
                              v-else-if="
                                settings.prefix.icon &&
                                settings.prefix.icon.indexOf('fa fa-') === 0
                              "
                              slot="prefix"
                              :class="settings.prefix.icon"
                              style="font-size: 1.2em"
                            />
                            <svg-icon
                              v-else-if="settings.prefix.icon"
                              slot="prefix"
                              :icon-class="settings.prefix.icon"
                              class="el-input__icon"
                              style="height: 28px; width: 16px"
                            />
                            <i
                              v-else
                              slot="prefix"
                              class="el-icon-search el-input__icon"
                            />
                            <i
                              v-if="settings.prefix.icon"
                              class="el-icon-close el-input__icon"
                              slot="suffix"
                              @click.stop="handleIconClickPrefix"
                            >
                            </i>
                          </el-input>
                        </el-popover>
                      </el-form-item>
                      <el-form-item label="图标颜色">
                        <el-color-picker
                          v-model="settings.prefix.iconColor"
                          @change="handleSettingTextChange"
                        ></el-color-picker>
                      </el-form-item>
                      <el-form-item label="图标大小">
                        <el-input-number
                          v-model="settings.prefix.iconSize"
                          controls-position="right"
                          :min="10"
                          :max="100"
                          @change="handleSettingTextChange"
                        />
                      </el-form-item>
                      <el-form-item label="图标边距">
                        <el-input-number
                          v-model="settings.prefix.iconMargin"
                          controls-position="right"
                          :min="0"
                          :max="30"
                          @change="handleSettingTextChange"
                        />
                      </el-form-item>
                      <el-form-item label="图片">
                        <file-upload
                          v-model="settings.prefix.image"
                          type="form"
                          title="前缀图片"
                          placeholder="前缀图片"
                          accept="image/png,image/jpeg"
                        />
                      </el-form-item>
                      <el-form-item label="图片宽度">
                        <el-input-number
                          v-model="settings.prefix.imageWidth"
                          controls-position="right"
                          :min="0"
                          :max="200"
                          @change="handleSettingTextChange"
                        />
                      </el-form-item>
                      <el-form-item label="图片高度">
                        <el-input-number
                          v-model="settings.prefix.imageHeight"
                          controls-position="right"
                          :min="0"
                          :max="200"
                          @change="handleSettingTextChange"
                        />
                      </el-form-item>
                      <el-form-item label="图片边距">
                        <el-input-number
                          v-model="settings.prefix.imageMargin"
                          controls-position="right"
                          :min="0"
                          :max="30"
                          @change="handleSettingTextChange"
                        />
                      </el-form-item>
                    </el-form>
                  </div>
                </el-collapse-item>
                <el-collapse-item
                  :style="
                    type == 'text' || type == 'kpi' || type == 'select'
                      ? ''
                      : 'display: none;'
                  "
                  title="后缀"
                  name="suffix"
                >
                  <template slot="title">
                    <div class="collapse-title">后缀</div>
                  </template>
                  <div class="collapse-content">
                    <el-form
                      label-position="top"
                      label-width="80px"
                      size="mini"
                    >
                      <el-form-item v-if="type == 'text'" label="方向">
                        <el-select
                          v-model="settings.suffix.direction"
                          @change="handleSettingChange"
                        >
                          <el-option
                            label="横向"
                            value="horizontal"
                          ></el-option>
                          <el-option label="纵向" value="vertical"></el-option>
                        </el-select>
                      </el-form-item>
                      <el-form-item label="文本">
                        <el-input
                          v-model="settings.suffix.text"
                          placeholder="后缀文本"
                          @change="handleSettingTextChange"
                        />
                      </el-form-item>
                      <el-form-item label="文本颜色">
                        <el-color-picker
                          v-model="settings.suffix.textColor"
                          @change="handleSettingTextChange"
                        ></el-color-picker>
                      </el-form-item>
                      <el-form-item label="字体大小">
                        <el-input-number
                          v-model="settings.suffix.fontSize"
                          controls-position="right"
                          :min="10"
                          :max="100"
                          @change="handleSettingTextChange"
                        />
                      </el-form-item>
                      <el-form-item label="字体加粗">
                        <el-switch v-model="settings.suffix.bold" />
                      </el-form-item>
                      <el-form-item label="文本边距">
                        <el-input-number
                          v-model="settings.suffix.textMargin"
                          controls-position="right"
                          :min="0"
                          :max="30"
                          @change="handleSettingTextChange"
                        />
                      </el-form-item>
                      <el-form-item label="图标">
                        <el-popover
                          placement="bottom-start"
                          width="630"
                          trigger="click"
                          @show="$refs['iconSelect'].reset()"
                        >
                          <icon-select
                            ref="iconSelect"
                            @selected="handleSelectSuffixIcon"
                          />
                          <el-input
                            slot="reference"
                            v-model="settings.suffix.icon"
                            placeholder="点击选择图标"
                            readonly
                            clearable
                          >
                            <i
                              v-if="
                                settings.suffix.icon &&
                                settings.suffix.icon.indexOf('el-icon-') === 0
                              "
                              slot="prefix"
                              :class="'el-icon ' + settings.suffix.icon"
                              style="font-size: 1.2em"
                            />
                            <i
                              v-else-if="
                                settings.suffix.icon &&
                                settings.suffix.icon.indexOf('fa fa-') === 0
                              "
                              slot="prefix"
                              :class="settings.suffix.icon"
                              style="font-size: 1.2em"
                            />
                            <svg-icon
                              v-else-if="settings.suffix.icon"
                              slot="prefix"
                              :icon-class="settings.suffix.icon"
                              class="el-input__icon"
                              style="height: 28px; width: 16px"
                            />
                            <i
                              v-else
                              slot="prefix"
                              class="el-icon-search el-input__icon"
                            />
                            <i
                              v-if="settings.suffix.icon"
                              class="el-icon-close el-input__icon"
                              slot="suffix"
                              @click.stop="handleIconClickSuffix"
                            >
                            </i>
                          </el-input>
                        </el-popover>
                      </el-form-item>
                      <el-form-item label="图标颜色">
                        <el-color-picker
                          v-model="settings.suffix.iconColor"
                          @change="handleSettingTextChange"
                        ></el-color-picker>
                      </el-form-item>
                      <el-form-item label="图标大小">
                        <el-input-number
                          v-model="settings.suffix.iconSize"
                          controls-position="right"
                          :min="10"
                          :max="100"
                          @change="handleSettingTextChange"
                        />
                      </el-form-item>
                      <el-form-item label="图标边距">
                        <el-input-number
                          v-model="settings.suffix.iconMargin"
                          controls-position="right"
                          :min="0"
                          :max="30"
                          @change="handleSettingTextChange"
                        />
                      </el-form-item>
                      <el-form-item label="图片">
                        <file-upload
                          v-model="settings.suffix.image"
                          type="form"
                          title="后缀图片"
                          placeholder="后缀图片"
                          accept="image/png,image/jpeg"
                        />
                      </el-form-item>
                      <el-form-item label="图片宽度">
                        <el-input-number
                          v-model="settings.suffix.imageWidth"
                          controls-position="right"
                          :min="0"
                          :max="200"
                          @change="handleSettingTextChange"
                        />
                      </el-form-item>
                      <el-form-item label="图片高度">
                        <el-input-number
                          v-model="settings.suffix.imageHeight"
                          controls-position="right"
                          :min="0"
                          :max="200"
                          @change="handleSettingTextChange"
                        />
                      </el-form-item>
                      <el-form-item label="图片边距">
                        <el-input-number
                          v-model="settings.suffix.imageMargin"
                          controls-position="right"
                          :min="0"
                          :max="30"
                          @change="handleSettingTextChange"
                        />
                      </el-form-item>
                    </el-form>
                  </div>
                </el-collapse-item>
                <el-collapse-item
                  :style="
                    type == 'text' || type == 'kpi' || type == 'select'
                      ? ''
                      : 'display: none;'
                  "
                  title="单位"
                  name="unit"
                >
                  <template slot="title">
                    <div class="collapse-title">单位</div>
                  </template>
                  <div class="collapse-content">
                    <el-form
                      label-position="top"
                      label-width="80px"
                      size="mini"
                    >
                      <el-form-item label="单位">
                        <el-input
                          v-model="settings.unit.text"
                          placeholder="比如：0=元 10000=万"
                          @change="handleSettingTextChange"
                        />
                      </el-form-item>
                      <el-form-item label="保留小数点">
                        <el-input-number
                          v-model="settings.unit.scale"
                          controls-position="right"
                          :min="0"
                          :max="10"
                          @change="handleSettingTextChange"
                        />
                      </el-form-item>
                      <el-form-item label="文本颜色">
                        <el-color-picker
                          v-model="settings.unit.textColor"
                          @change="handleSettingTextChange"
                        ></el-color-picker>
                      </el-form-item>
                      <el-form-item label="字体大小">
                        <el-input-number
                          v-model="settings.unit.fontSize"
                          controls-position="right"
                          :min="10"
                          :max="100"
                          @change="handleSettingTextChange"
                        />
                      </el-form-item>
                      <el-form-item label="字体加粗">
                        <el-switch v-model="settings.unit.bold" />
                      </el-form-item>
                      <el-form-item label="文本边距">
                        <el-input-number
                          v-model="settings.unit.textMargin"
                          controls-position="right"
                          :min="0"
                          :max="30"
                          @change="handleSettingTextChange"
                        />
                      </el-form-item>
                      <el-form-item label="启用动画">
                        <el-switch
                          v-model="settings.unit.animate"
                          @change="handleSettingTextChange"
                        />
                      </el-form-item>
                      <el-form-item label="动画时间（毫秒）">
                        <el-input-number
                          v-model="settings.unit.animateDuration"
                          controls-position="right"
                          :min="100"
                          :max="5000"
                          @change="handleSettingTextChange"
                        />
                      </el-form-item>
                    </el-form>
                  </div>
                </el-collapse-item>
                <el-collapse-item
                  :style="type == 'select' ? '' : 'display: none;'"
                  title="选择框"
                  name="select"
                >
                  <template slot="title">
                    <div class="collapse-title">选择框</div>
                  </template>
                  <div class="collapse-content">
                    <el-form
                      label-position="top"
                      label-width="80px"
                      size="mini"
                    >
                      <el-form-item label="主题">
                        <el-select v-model="settings.select.theme">
                          <el-option label="浅色" value="light"></el-option>
                          <el-option label="深色" value="dark"></el-option>
                        </el-select>
                      </el-form-item>
                      <el-form-item label="弹出位置">
                        <el-select v-model="settings.select.position">
                          <el-option
                            label="左下"
                            value="leftbottom"
                          ></el-option>
                          <el-option
                            label="右下"
                            value="rightbottom"
                          ></el-option>
                          <el-option label="左上" value="lefttop"></el-option>
                          <el-option label="右上" value="righttop"></el-option>
                        </el-select>
                      </el-form-item>
                      <el-form-item label="横向偏移">
                        <el-input-number
                          v-model="settings.select.hoffset"
                          controls-position="right"
                          @change="handleSettingChange"
                        />
                      </el-form-item>
                      <el-form-item label="纵向偏移">
                        <el-input-number
                          v-model="settings.select.voffset"
                          controls-position="right"
                          @change="handleSettingChange"
                        />
                      </el-form-item>
                      <el-form-item label="默认值">
                        <el-input
                          v-model="value_input"
                          placeholder="默认值"
                          @change="handleValueChange"
                        />
                      </el-form-item>
                    </el-form>
                  </div>
                </el-collapse-item>
                <el-collapse-item
                  :style="type == 'rank' ? '' : 'display: none;'"
                  title="表头"
                  name="tableHeader"
                >
                  <template slot="title">
                    <div class="collapse-title">表头</div>
                  </template>
                  <div class="collapse-content">
                    <el-form
                      label-position="top"
                      label-width="80px"
                      size="mini"
                    >
                      <el-form-item label="表头高度">
                        <el-input-number
                          v-model="settings.table.headerHeight"
                          controls-position="right"
                          :min="0"
                          :max="50"
                          @change="handleSettingSizeChange"
                        />
                      </el-form-item>
                      <el-form-item label="文本颜色">
                        <el-color-picker
                          v-model="settings.table.headerTextColor"
                          @change="handleSettingChange"
                        ></el-color-picker>
                      </el-form-item>
                      <el-form-item label="字体大小">
                        <el-input-number
                          v-model="settings.table.headerFontSize"
                          controls-position="right"
                          :min="10"
                          :max="30"
                          @change="handleSettingSizeChange"
                        />
                      </el-form-item>
                      <el-form-item label="字体加粗">
                        <el-switch v-model="settings.table.headerFontBold" />
                      </el-form-item>
                      <el-form-item label="文本纵向对齐">
                        <el-select v-model="settings.table.headerTextValign">
                          <el-option label="顶部对齐" value="top"></el-option>
                          <el-option label="区中" value="middle"></el-option>
                          <el-option
                            label="底部对齐"
                            value="bottom"
                          ></el-option>
                        </el-select>
                      </el-form-item>
                      <el-form-item label="背景颜色">
                        <el-color-picker
                          v-model="settings.table.headerBackgroundColor"
                          @change="handleSettingChange"
                          show-alpha
                        ></el-color-picker>
                      </el-form-item>
                      <el-form-item label="边框颜色">
                        <el-color-picker
                          v-model="settings.table.headerBorderColor"
                          @change="handleSettingSizeChange"
                          show-alpha
                        ></el-color-picker>
                      </el-form-item>
                      <el-form-item label="左边框宽度">
                        <el-input-number
                          v-model="settings.table.headerBorderLeft"
                          controls-position="right"
                          :min="0"
                          :max="10"
                          @change="handleSettingSizeChange"
                        />
                      </el-form-item>
                      <el-form-item label="右边框宽度">
                        <el-input-number
                          v-model="settings.table.headerBorderRight"
                          controls-position="right"
                          :min="0"
                          :max="10"
                          @change="handleSettingSizeChange"
                        />
                      </el-form-item>
                      <el-form-item label="上边框宽度">
                        <el-input-number
                          v-model="settings.table.headerBorderTop"
                          controls-position="right"
                          :min="0"
                          :max="10"
                          @change="handleSettingSizeChange"
                        />
                      </el-form-item>
                      <el-form-item label="下边框宽度">
                        <el-input-number
                          v-model="settings.table.headerBorderBottom"
                          controls-position="right"
                          :min="0"
                          :max="10"
                          @change="handleSettingSizeChange"
                        />
                      </el-form-item>
                      <el-form-item label="序号列名称">
                        <el-input
                          v-model="settings.table.indexName"
                          @change="handleSettingSizeChange"
                        />
                      </el-form-item>
                      <el-form-item label="隐藏表头">
                        <el-switch
                          v-model="settings.table.headerHidden"
                          @change="handleSettingSizeChange"
                        />
                      </el-form-item>
                    </el-form>
                  </div>
                </el-collapse-item>
                <el-collapse-item
                  :style="type == 'rank' ? '' : 'display: none;'"
                  title="表格"
                  name="tableBody"
                >
                  <template slot="title">
                    <div class="collapse-title">表格</div>
                  </template>
                  <div class="collapse-content">
                    <el-form
                      label-position="top"
                      label-width="80px"
                      size="mini"
                    >
                      <el-form-item label="行高">
                        <el-input-number
                          v-model="settings.table.rowHeight"
                          controls-position="right"
                          :min="0"
                          :max="50"
                          @change="handleSettingSizeChange"
                        />
                      </el-form-item>
                      <el-form-item label="列最小宽度">
                        <el-input-number
                          v-model="settings.table.colMinWidth"
                          controls-position="right"
                          :min="0"
                          @change="handleSettingSizeChange"
                        />
                      </el-form-item>
                      <el-form-item label="序号列最小宽度">
                        <el-input-number
                          v-model="settings.table.indexColMinWidth"
                          controls-position="right"
                          :min="0"
                          @change="handleSettingSizeChange"
                        />
                      </el-form-item>
                      <el-form-item label="文本横向对齐">
                        <el-select v-model="settings.table.textAlign">
                          <el-option label="自动" value=""></el-option>
                          <el-option label="左对齐" value="left"></el-option>
                          <el-option label="区中" value="center"></el-option>
                          <el-option label="右对齐" value="right"></el-option>
                        </el-select>
                      </el-form-item>
                      <el-form-item label="奇数行文本颜色">
                        <el-color-picker
                          v-model="settings.table.rowTextColor1"
                          @change="handleSettingChange"
                        ></el-color-picker>
                      </el-form-item>
                      <el-form-item label="奇数行背景颜色">
                        <el-color-picker
                          v-model="settings.table.rowBackgroundColor1"
                          @change="handleSettingChange"
                          show-alpha
                        ></el-color-picker>
                      </el-form-item>
                      <el-form-item label="偶数行文本颜色">
                        <el-color-picker
                          v-model="settings.table.rowTextColor2"
                          @change="handleSettingChange"
                        ></el-color-picker>
                      </el-form-item>
                      <el-form-item label="偶数行背景颜色">
                        <el-color-picker
                          v-model="settings.table.rowBackgroundColor2"
                          @change="handleSettingChange"
                          show-alpha
                        ></el-color-picker>
                      </el-form-item>
                      <el-form-item label="选中行文本颜色">
                        <el-color-picker
                          v-model="settings.table.rowTextColorSelect"
                          @change="handleSettingChange"
                        ></el-color-picker>
                      </el-form-item>
                      <el-form-item label="选中行背景颜色">
                        <el-color-picker
                          v-model="settings.table.rowBackgroundColorSelect"
                          @change="handleSettingChange"
                          show-alpha
                        ></el-color-picker>
                      </el-form-item>
                      <el-form-item label="边框颜色">
                        <el-color-picker
                          v-model="settings.table.rowBorderColor"
                          @change="handleSettingSizeChange"
                          show-alpha
                        ></el-color-picker>
                      </el-form-item>
                      <el-form-item label="左边框宽度">
                        <el-input-number
                          v-model="settings.table.rowBorderLeft"
                          controls-position="right"
                          :min="0"
                          :max="10"
                          @change="handleSettingSizeChange"
                        />
                      </el-form-item>
                      <el-form-item label="右边框宽度">
                        <el-input-number
                          v-model="settings.table.rowBorderRight"
                          controls-position="right"
                          :min="0"
                          :max="10"
                          @change="handleSettingSizeChange"
                        />
                      </el-form-item>
                      <el-form-item label="上边框宽度">
                        <el-input-number
                          v-model="settings.table.rowBorderTop"
                          controls-position="right"
                          :min="0"
                          :max="10"
                          @change="handleSettingSizeChange"
                        />
                      </el-form-item>
                      <el-form-item label="下边框宽度">
                        <el-input-number
                          v-model="settings.table.rowBorderBottom"
                          controls-position="right"
                          :min="0"
                          :max="10"
                          @change="handleSettingSizeChange"
                        />
                      </el-form-item>
                      <el-form-item label="边距">
                        <el-input-number
                          v-model="settings.table.padding"
                          controls-position="right"
                          :min="0"
                          :max="50"
                          @change="handleSettingSizeChange"
                        />
                      </el-form-item>
                      <el-form-item label="滚动速度">
                        <el-select
                          v-model="settings.table.step"
                          @change="handleSettingChange"
                        >
                          <el-option label="不滚动" value="0"></el-option>
                          <el-option label="极慢" value="0.2"></el-option>
                          <el-option label="慢" value="0.4"></el-option>
                          <el-option label="正常" value="0.6"></el-option>
                          <el-option label="快" value="1"></el-option>
                          <el-option label="极快" value="1.5"></el-option>
                        </el-select>
                      </el-form-item>
                      <el-form-item label="单步停顿">
                        <el-switch
                          v-model="settings.table.singlePause"
                          @change="handleSettingChange"
                        />
                      </el-form-item>
                      <el-form-item label="单步停顿时间（秒）">
                        <el-input-number
                          v-model="settings.table.waitTime"
                          controls-position="right"
                          :min="0.5"
                          :max="5"
                          @change="handleSettingChange"
                        />
                      </el-form-item>
                      <el-form-item label="隐藏序号">
                        <el-switch
                          v-model="settings.table.indexHidden"
                          @change="handleSettingSizeChange"
                        />
                      </el-form-item>
                      <el-form-item label="id字段">
                        <el-input
                          v-model="settings.table.idField"
                          placeholder="默认第一非序号列"
                          @change="handleSettingChange"
                        />
                      </el-form-item>
                    </el-form>
                  </div>
                </el-collapse-item>
                <el-collapse-item
                  :style="
                    type == 'pie' ||
                    type == 'ring' ||
                    type == 'line' ||
                    type == 'area' ||
                    type == 'histogram' ||
                    type == 'bar' ||
                    type == 'funnel' ||
                    type == 'radar' ||
                    type == 'scatter' ||
                    type == 'map'
                      ? ''
                      : 'display: none;'
                  "
                  title="主题"
                  name="color"
                >
                  <template slot="title">
                    <div class="collapse-title">主题</div>
                  </template>
                  <div class="collapse-content">
                    <el-form
                      label-position="top"
                      label-width="80px"
                      size="mini"
                    >
                      <el-form-item label="配色">
                        <el-color-picker
                          v-model="settings.theme.color[0]"
                          show-alpha
                          @change="handleSettingThemeChange"
                        ></el-color-picker>
                        <el-color-picker
                          v-model="settings.theme.color[1]"
                          show-alpha
                          @change="handleSettingThemeChange"
                        ></el-color-picker>
                        <el-color-picker
                          v-model="settings.theme.color[2]"
                          show-alpha
                          @change="handleSettingThemeChange"
                        ></el-color-picker>
                        <el-color-picker
                          v-model="settings.theme.color[3]"
                          show-alpha
                          @change="handleSettingThemeChange"
                        ></el-color-picker>
                        <el-color-picker
                          v-model="settings.theme.color[4]"
                          show-alpha
                          @change="handleSettingThemeChange"
                        ></el-color-picker>
                        <el-color-picker
                          v-model="settings.theme.color[5]"
                          show-alpha
                          @change="handleSettingThemeChange"
                        ></el-color-picker>
                        <el-color-picker
                          v-model="settings.theme.color[6]"
                          show-alpha
                          @change="handleSettingThemeChange"
                        ></el-color-picker>
                        <el-color-picker
                          v-model="settings.theme.color[7]"
                          show-alpha
                          @change="handleSettingThemeChange"
                        ></el-color-picker>
                        <el-color-picker
                          v-model="settings.theme.color[8]"
                          show-alpha
                          @change="handleSettingThemeChange"
                        ></el-color-picker>
                        <el-color-picker
                          v-model="settings.theme.color[9]"
                          show-alpha
                          @change="handleSettingThemeChange"
                        ></el-color-picker>
                        <el-color-picker
                          v-model="settings.theme.color[10]"
                          show-alpha
                          @change="handleSettingThemeChange"
                        ></el-color-picker>
                        <el-color-picker
                          v-model="settings.theme.color[11]"
                          show-alpha
                          @change="handleSettingThemeChange"
                        ></el-color-picker>
                        <el-color-picker
                          v-model="settings.theme.color[12]"
                          show-alpha
                          @change="handleSettingThemeChange"
                        ></el-color-picker>
                      </el-form-item>
                    </el-form>
                  </div>
                </el-collapse-item>
                <el-collapse-item
                  :style="
                    type == 'pie' ||
                    type == 'ring' ||
                    type == 'line' ||
                    type == 'area' ||
                    type == 'histogram' ||
                    type == 'bar' ||
                    type == 'funnel' ||
                    type == 'radar' ||
                    type == 'scatter' ||
                    type == 'map'
                      ? ''
                      : 'display: none;'
                  "
                  title="图例"
                  name="legend"
                >
                  <template slot="title">
                    <div class="collapse-title">图例</div>
                  </template>
                  <div class="collapse-content">
                    <el-form
                      label-position="top"
                      label-width="80px"
                      size="mini"
                    >
                      <el-form-item label="方向">
                        <el-select
                          v-model="settings.legend.orient"
                          @change="handleSettingChange"
                        >
                          <el-option
                            label="横向"
                            value="horizontal"
                          ></el-option>
                          <el-option label="纵向" value="vertical"></el-option>
                        </el-select>
                      </el-form-item>
                      <el-form-item label="横向位置">
                        <el-input
                          placeholder="偏移像素或百分比"
                          v-model="settings.legend.alignOffset"
                          class="input-with-select"
                          @change="handleSettingChange"
                        >
                          <el-select
                            v-model="settings.legend.align"
                            slot="prepend"
                            placeholder=""
                            style="width: 60px"
                            @change="handleSettingChange"
                          >
                            <el-option label="左" value="left"></el-option>
                            <el-option label="中" value="center"></el-option>
                            <el-option label="右" value="right"></el-option>
                          </el-select>
                        </el-input>
                      </el-form-item>
                      <el-form-item label="纵向位置">
                        <el-input
                          placeholder="偏移像素或百分比"
                          v-model="settings.legend.valignOffset"
                          class="input-with-select"
                          @change="handleSettingChange"
                        >
                          <el-select
                            v-model="settings.legend.valign"
                            slot="prepend"
                            placeholder=""
                            style="width: 60px"
                            @change="handleSettingChange"
                          >
                            <el-option label="上" value="top"></el-option>
                            <el-option label="中" value="middle"></el-option>
                            <el-option label="下" value="bottom"></el-option>
                          </el-select>
                        </el-input>
                      </el-form-item>
                      <el-form-item label="显示">
                        <el-switch
                          v-model="settings.legend.show"
                          @change="handleSettingChange"
                        />
                      </el-form-item>
                    </el-form>
                  </div>
                </el-collapse-item>
                <el-collapse-item
                  :style="
                    type == 'line' ||
                    type == 'area' ||
                    type == 'histogram' ||
                    type == 'bar'
                      ? ''
                      : 'display: none;'
                  "
                  title="X轴"
                  name="xAxis"
                >
                  <template slot="title">
                    <div class="collapse-title">X轴</div>
                  </template>
                  <div class="collapse-content">
                    <el-form
                      label-position="top"
                      label-width="80px"
                      size="mini"
                    >
                      <el-form-item label="标题">
                        <el-input
                          v-model="settings.xAxis.name"
                          @change="handleSettingChange"
                        />
                      </el-form-item>
                      <el-form-item label="坐标轴颜色">
                        <el-color-picker
                          v-model="settings.xAxis.axisLine.lineStyle.color"
                          show-alpha
                          @change="handleSettingChange"
                        ></el-color-picker>
                      </el-form-item>
                      <el-form-item label="坐标轴类型">
                        <el-select
                          v-model="settings.xAxis.axisLine.lineStyle.type"
                          @change="handleSettingChange"
                        >
                          <el-option label="实线" value="solid"></el-option>
                          <el-option label="虚线" value="dashed"></el-option>
                        </el-select>
                      </el-form-item>
                      <el-form-item label="显示坐标轴">
                        <el-switch
                          v-model="settings.xAxis.axisLine.show"
                          @change="handleSettingChange"
                        />
                      </el-form-item>
                      <el-form-item label="分割线颜色">
                        <el-color-picker
                          v-model="settings.xAxis.splitLine.lineStyle.color"
                          show-alpha
                          @change="handleSettingChange"
                        ></el-color-picker>
                      </el-form-item>
                      <el-form-item label="分割线类型">
                        <el-select
                          v-model="settings.xAxis.splitLine.lineStyle.type"
                          @change="handleSettingChange"
                        >
                          <el-option label="实线" value="solid"></el-option>
                          <el-option label="虚线" value="dashed"></el-option>
                        </el-select>
                      </el-form-item>
                      <el-form-item label="显示分割线">
                        <el-switch
                          v-model="settings.xAxis.splitLine.show"
                          @change="handleSettingChange"
                        />
                      </el-form-item>
                    </el-form>
                  </div>
                </el-collapse-item>
                <el-collapse-item
                  :style="
                    type == 'line' ||
                    type == 'area' ||
                    type == 'histogram' ||
                    type == 'bar'
                      ? ''
                      : 'display: none;'
                  "
                  title="Y轴"
                  name="yAxis"
                >
                  <template slot="title">
                    <div class="collapse-title">Y轴</div>
                  </template>
                  <div class="collapse-content">
                    <el-form
                      label-position="top"
                      label-width="80px"
                      size="mini"
                    >
                      <el-form-item label="标题">
                        <el-input
                          v-model="settings.yAxis.name"
                          @change="handleSettingChange"
                        />
                      </el-form-item>
                      <el-form-item label="坐标轴颜色">
                        <el-color-picker
                          v-model="settings.yAxis.axisLine.lineStyle.color"
                          show-alpha
                          @change="handleSettingChange"
                        ></el-color-picker>
                      </el-form-item>
                      <el-form-item label="坐标轴类型">
                        <el-select
                          v-model="settings.yAxis.axisLine.lineStyle.type"
                          @change="handleSettingChange"
                        >
                          <el-option label="实线" value="solid"></el-option>
                          <el-option label="虚线" value="dashed"></el-option>
                        </el-select>
                      </el-form-item>
                      <el-form-item label="显示坐标轴">
                        <el-switch
                          v-model="settings.yAxis.axisLine.show"
                          @change="handleSettingChange"
                        />
                      </el-form-item>
                      <el-form-item label="分割线颜色">
                        <el-color-picker
                          v-model="settings.yAxis.splitLine.lineStyle.color"
                          show-alpha
                          @change="handleSettingChange"
                        ></el-color-picker>
                      </el-form-item>
                      <el-form-item label="分割线类型">
                        <el-select
                          v-model="settings.yAxis.splitLine.lineStyle.type"
                          @change="handleSettingChange"
                        >
                          <el-option label="实线" value="solid"></el-option>
                          <el-option label="虚线" value="dashed"></el-option>
                        </el-select>
                      </el-form-item>
                      <el-form-item label="显示分割线">
                        <el-switch
                          v-model="settings.yAxis.splitLine.show"
                          @change="handleSettingChange"
                        />
                      </el-form-item>
                    </el-form>
                  </div>
                </el-collapse-item>
                <el-collapse-item
                  :style="
                    type == 'histogram' || type == 'bar' ? '' : 'display: none;'
                  "
                  title="系列"
                  name="series"
                >
                  <template slot="title">
                    <div class="collapse-title">系列</div>
                  </template>
                  <div class="collapse-content">
                    <el-form
                      label-position="top"
                      label-width="80px"
                      size="mini"
                    >
                      <el-form-item label="显示文本">
                        <el-switch
                          v-model="settings.series.label.show"
                          @change="handleSettingChange"
                        />
                      </el-form-item>
                      <el-form-item label="文本位置">
                        <el-select
                          v-model="settings.series.label.position"
                          @change="handleSettingChange"
                        >
                          <el-option label="自动" value=""></el-option>
                          <el-option label="上" value="top"></el-option>
                          <el-option label="下" value="bottom"></el-option>
                          <el-option label="左" value="left"></el-option>
                          <el-option label="右" value="right"></el-option>
                        </el-select>
                      </el-form-item>
                      <el-form-item label="格式化">
                        <el-input
                          v-model="settings.series.label.formatter"
                          @change="handleSettingSeriesFormatterChange"
                          clearable
                        />
                      </el-form-item>
                    </el-form>
                  </div>
                </el-collapse-item>
                <el-collapse-item
                  :style="
                    type == 'line' ||
                    type == 'area' ||
                    type == 'histogram' ||
                    type == 'bar'
                      ? ''
                      : 'display: none;'
                  "
                  title="偏移"
                  name="grid"
                >
                  <template slot="title">
                    <div class="collapse-title">偏移</div>
                  </template>
                  <div class="collapse-content">
                    <el-form
                      label-position="top"
                      label-width="80px"
                      size="mini"
                    >
                      <el-form-item label="左">
                        <el-input
                          v-model="settings.grid.left"
                          placeholder="偏移像素或百分比"
                          @change="handleSettingChange"
                        />
                      </el-form-item>
                      <el-form-item label="右">
                        <el-input
                          v-model="settings.grid.right"
                          placeholder="偏移像素或百分比"
                          @change="handleSettingChange"
                        />
                      </el-form-item>
                      <el-form-item label="上">
                        <el-input
                          v-model="settings.grid.top"
                          placeholder="偏移像素或百分比"
                          @change="handleSettingChange"
                        />
                      </el-form-item>
                      <el-form-item label="下">
                        <el-input
                          v-model="settings.grid.bottom"
                          placeholder="偏移像素或百分比"
                          @change="handleSettingChange"
                        />
                      </el-form-item>
                    </el-form>
                  </div>
                </el-collapse-item>
                <el-collapse-item
                  :style="
                    type == 'pie' ||
                    type == 'ring' ||
                    type == 'bar' ||
                    type == 'histogram'
                      ? ''
                      : 'display: none;'
                  "
                  title="尺寸"
                  name="size"
                >
                  <template slot="title">
                    <div class="collapse-title">尺寸</div>
                  </template>
                  <div class="collapse-content">
                    <el-form
                      label-position="top"
                      label-width="80px"
                      size="mini"
                    >
                      <el-form-item
                        v-if="type == 'pie' || type == 'ring'"
                        label="外径"
                      >
                        <el-input
                          v-model="settings.size.outsideRadius"
                          placeholder="偏移像素或百分比"
                          @change="handleSettingChange"
                        />
                      </el-form-item>
                      <el-form-item v-if="type == 'ring'" label="内径">
                        <el-input
                          v-model="settings.size.insideRadius"
                          placeholder="偏移像素或百分比"
                          @change="handleSettingChange"
                        />
                      </el-form-item>
                      <el-form-item
                        v-if="type == 'bar' || type == 'histogram'"
                        label="宽度"
                      >
                        <el-input
                          v-model="settings.size.width"
                          placeholder="偏移像素或百分比"
                          @change="handleSettingChange"
                        />
                      </el-form-item>
                    </el-form>
                  </div>
                </el-collapse-item>
                <el-collapse-item
                  :style="type == 'datav' ? '' : 'display: none;'"
                  title="datav"
                  name="datav"
                >
                  <template slot="title">
                    <div class="collapse-title">datav</div>
                  </template>
                  <div class="collapse-content">
                    <el-form
                      label-position="top"
                      label-width="80px"
                      size="mini"
                    >
                      <el-form-item label="类型">
                        <el-select
                          v-model="settings.datav.type"
                          filterable
                          @change="handleSettingDataVTypeChange"
                        >
                          <el-option
                            label="加载"
                            value="dv-loading"
                          ></el-option>
                          <el-option
                            label="边框"
                            value="dv-border-box"
                          ></el-option>
                          <el-option
                            label="装饰"
                            value="dv-decoration"
                          ></el-option>
                          <el-option label="图表" value="dv-charts"></el-option>
                          <el-option
                            label="动态环图"
                            value="dv-active-ring-chart"
                          ></el-option>
                          <el-option
                            label="胶囊柱图"
                            value="dv-capsule-chart"
                          ></el-option>
                          <el-option
                            label="水位图"
                            value="dv-water-level-pond"
                          ></el-option>
                          <el-option
                            label="进度池"
                            value="dv-percent-pond"
                          ></el-option>
                          <el-option
                            label="飞线图"
                            value="dv-flyline-chart"
                          ></el-option>
                          <el-option
                            label="数字翻牌器"
                            value="dv-digital-flop"
                          ></el-option>
                          <el-option
                            label="轮播表"
                            value="dv-scroll-board"
                          ></el-option>
                          <el-option
                            label="排名轮播表"
                            value="dv-scroll-ranking-board"
                          ></el-option>
                        </el-select>
                      </el-form-item>
                      <el-form-item
                        v-if="settings.datav.type == 'dv-border-box'"
                        label="边框类型"
                      >
                        <el-select v-model="settings.datav.subType" filterable>
                          <el-option
                            label="边框1"
                            value="dv-border-box-1"
                          ></el-option>
                          <el-option
                            label="边框2"
                            value="dv-border-box-2"
                          ></el-option>
                          <el-option
                            label="边框3"
                            value="dv-border-box-3"
                          ></el-option>
                          <el-option
                            label="边框4"
                            value="dv-border-box-4"
                          ></el-option>
                          <el-option
                            label="边框5"
                            value="dv-border-box-5"
                          ></el-option>
                          <el-option
                            label="边框6"
                            value="dv-border-box-6"
                          ></el-option>
                          <el-option
                            label="边框7"
                            value="dv-border-box-7"
                          ></el-option>
                          <el-option
                            label="边框8"
                            value="dv-border-box-8"
                          ></el-option>
                          <el-option
                            label="边框9"
                            value="dv-border-box-9"
                          ></el-option>
                          <el-option
                            label="边框10"
                            value="dv-border-box-10"
                          ></el-option>
                          <el-option
                            label="边框11"
                            value="dv-border-box-11"
                          ></el-option>
                          <el-option
                            label="边框12"
                            value="dv-border-box-12"
                          ></el-option>
                          <el-option
                            label="边框13"
                            value="dv-border-box-13"
                          ></el-option>
                        </el-select>
                      </el-form-item>
                      <el-form-item
                        v-else-if="settings.datav.type == 'dv-decoration'"
                        label="装饰类型"
                      >
                        <el-select v-model="settings.datav.subType" filterable>
                          <el-option
                            label="装饰1"
                            value="dv-decoration-1"
                          ></el-option>
                          <el-option
                            label="装饰2"
                            value="dv-decoration-2"
                          ></el-option>
                          <el-option
                            label="装饰3"
                            value="dv-decoration-3"
                          ></el-option>
                          <el-option
                            label="装饰4"
                            value="dv-decoration-4"
                          ></el-option>
                          <el-option
                            label="装饰5"
                            value="dv-decoration-5"
                          ></el-option>
                          <el-option
                            label="装饰6"
                            value="dv-decoration-6"
                          ></el-option>
                          <el-option
                            label="装饰7"
                            value="dv-decoration-7"
                          ></el-option>
                          <el-option
                            label="装饰8"
                            value="dv-decoration-8"
                          ></el-option>
                          <el-option
                            label="装饰9"
                            value="dv-decoration-9"
                          ></el-option>
                          <el-option
                            label="装饰10"
                            value="dv-decoration-10"
                          ></el-option>
                          <el-option
                            label="装饰11"
                            value="dv-decoration-11"
                          ></el-option>
                          <el-option
                            label="装饰12"
                            value="dv-decoration-12"
                          ></el-option>
                        </el-select>
                      </el-form-item>
                      <el-form-item
                        v-else-if="settings.datav.type == 'dv-charts'"
                        label="图表"
                      >
                        <el-select v-model="settings.datav.subType" filterable>
                          <el-option label="折线图" value="line"></el-option>
                          <el-option label="柱状图" value="bar"></el-option>
                          <el-option label="饼状图" value="pie"></el-option>
                          <el-option label="雷达图" value="radar"></el-option>
                          <el-option label="仪表盘" value="gauge"></el-option>
                        </el-select>
                      </el-form-item>
                      <el-form-item v-if="showDatavReverseConfig" label="反转">
                        <el-switch
                          v-model="settings.datav.reverse"
                          @change="handleSettingChange"
                        />
                      </el-form-item>
                      <el-form-item
                        v-if="
                          settings.datav.type == 'dv-border-box' ||
                          settings.datav.type == 'dv-decoration'
                        "
                        label="动画时间"
                      >
                        <el-input-number
                          v-model="settings.datav.dur"
                          controls-position="right"
                          :min="0"
                          :max="15"
                          @change="handleSettingChange"
                        />
                      </el-form-item>
                    </el-form>
                  </div>
                </el-collapse-item>
                <el-collapse-item
                  :style="type == 'image' ? '' : 'display: none;'"
                  title="图片"
                  name="image"
                >
                  <template slot="title">
                    <div class="collapse-title">图片</div>
                  </template>
                  <div class="collapse-content">
                    <el-form
                      label-position="top"
                      label-width="80px"
                      size="mini"
                    >
                      <el-form-item label="缩放">
                        <el-select v-model="settings.image.scale">
                          <el-option label="不缩放" value=""></el-option>
                          <el-option label="自适应" value="auto"></el-option>
                          <el-option
                            label="等比例缩放"
                            value="cover"
                          ></el-option>
                        </el-select>
                      </el-form-item>
                    </el-form>
                  </div>
                </el-collapse-item>
                <el-collapse-item
                  :style="type == 'map' ? '' : 'display: none;'"
                  title="地图"
                  name="map"
                >
                  <template slot="title">
                    <div class="collapse-title">地图</div>
                  </template>
                  <div class="collapse-content">
                    <el-form
                      label-position="top"
                      label-width="80px"
                      size="mini"
                    >
                      <el-form-item label="地图">
                        <el-select
                          v-model="settings.map.map"
                          filterable
                          @change="handleSettingChange"
                        >
                          <el-option label="世界" value="world"></el-option>
                          <el-option label="中国" value="china"></el-option>
                          <!--<el-option label="中国（轮廓）" value="china-contour"></el-option>
                          <el-option label="中国（城市）" value="china-cities"></el-option>-->
                        </el-select>
                      </el-form-item>
                      <el-form-item label="显示文本">
                        <el-switch
                          v-model="settings.map.normalLabelShow"
                          @change="handleSettingChange"
                        />
                      </el-form-item>
                      <el-form-item label="文本颜色">
                        <el-color-picker
                          v-model="settings.map.normalLabelColor"
                          @change="handleSettingChange"
                        ></el-color-picker>
                      </el-form-item>
                      <el-form-item label="字体大小">
                        <el-input-number
                          v-model="settings.map.normalLabelFontSize"
                          controls-position="right"
                          :min="10"
                          :max="30"
                          @change="handleSettingChange"
                        />
                      </el-form-item>
                      <el-form-item label="显示高亮文本">
                        <el-switch
                          v-model="settings.map.emphasisLabelShow"
                          @change="handleSettingChange"
                        />
                      </el-form-item>
                      <el-form-item label="高亮文本颜色">
                        <el-color-picker
                          v-model="settings.map.emphasisLabelColor"
                          @change="handleSettingChange"
                        ></el-color-picker>
                      </el-form-item>
                      <el-form-item label="高亮字体大小">
                        <el-input-number
                          v-model="settings.map.emphasisLabelFontSize"
                          controls-position="right"
                          :min="10"
                          :max="30"
                          @change="handleSettingChange"
                        />
                      </el-form-item>
                      <el-form-item label="区域颜色">
                        <el-color-picker
                          v-model="settings.map.normalAreaColor"
                          @change="handleSettingChange"
                        ></el-color-picker>
                      </el-form-item>
                      <el-form-item label="边框颜色">
                        <el-color-picker
                          v-model="settings.map.normalBorderColor"
                          @change="handleSettingChange"
                        ></el-color-picker>
                      </el-form-item>
                      <el-form-item label="边框大小">
                        <el-input-number
                          v-model="settings.map.normalBorderWidth"
                          controls-position="right"
                          :min="0"
                          :max="3"
                          @change="handleSettingChange"
                        />
                      </el-form-item>
                      <el-form-item label="高亮区域颜色">
                        <el-color-picker
                          v-model="settings.map.emphasisAreaColor"
                          @change="handleSettingChange"
                        ></el-color-picker>
                      </el-form-item>
                      <el-form-item label="高亮边框颜色">
                        <el-color-picker
                          v-model="settings.map.emphasisBorderColor"
                          @change="handleSettingChange"
                        ></el-color-picker>
                      </el-form-item>
                      <el-form-item label="高亮边框大小">
                        <el-input-number
                          v-model="settings.map.emphasisBorderWidth"
                          controls-position="right"
                          :min="0"
                          :max="3"
                          @change="handleSettingChange"
                        />
                      </el-form-item>
                    </el-form>
                  </div>
                </el-collapse-item>
              </el-collapse>
              <div
                v-if="
                  type == 'text' ||
                  type == 'kpi' ||
                  type == 'echarts' ||
                  type == 'datav'
                "
                style="
                  width: 100%;
                  display: flex;
                  justify-content: center;
                  margin-top: 20px;
                "
              >
                <el-button
                  type="default"
                  size="small"
                  @click="handleEditDataFormatter"
                  >数据处理</el-button
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        style="
          display: flex;
          flex-direction: column;
          flex: 1;
          padding: 10px 10px;
        "
      >
        <div
          v-if="
            type == 'text' ||
            type == 'image' ||
            type == 'video' ||
            type == 'web'
          "
          class="target-fields-container"
          style="margin-bottom: 10px"
        >
          <div class="target-fields-title">默认</div>
          <div class="target-fields">
            <input
              v-if="type == 'text'"
              v-model="value"
              placeholder="默认值"
              style="border: none; outline: none; flex: 1; height: 100%"
            />
            <input
              v-else
              v-model="value_input"
              placeholder="默认值"
              style="border: none; outline: none; flex: 1; height: 100%"
              @blur="handleValueChange"
            />
            <el-button
              v-if="type == 'image' || type == 'video'"
              size="mini"
              icon="el-icon-upload2"
              @click="handleUploadFile"
              >上传</el-button
            >
          </div>
        </div>
        <div
          v-else
          id="dimension"
          ref="dimension"
          class="target-fields-container"
          style="margin-bottom: 10px"
          @drop="handleDrop('dimension')"
          @dragover="handleDragOver('dimension')"
          @dragleave="handleDragLeave('dimension')"
        >
          <div v-if="type == 'select'" class="target-fields-title">标签</div>
          <div v-else class="target-fields-title">维度</div>
          <div class="target-fields">
            <div v-if="dimension.length == 0" class="target-fields-placeholder">
              请从右侧拖入字段
            </div>
            <el-tag
              v-else
              v-for="tag in dimension"
              :key="tag"
              closable
              type=""
              @close="handleRemoveDimensions(tag)"
            >
              {{ tag }}
            </el-tag>
          </div>
        </div>
        <div
          id="metrics"
          ref="metrics"
          class="target-fields-container"
          style="margin-bottom: 10px"
          @drop="handleDrop('metrics')"
          @dragover="handleDragOver('metrics')"
          @dragleave="handleDragLeave('metrics')"
        >
          <div v-if="type == 'text'" class="target-fields-title">文本</div>
          <div v-else-if="type == 'select'" class="target-fields-title">
            数值
          </div>
          <div
            v-else-if="type == 'image' || type == 'video' || type == 'web'"
            class="target-fields-title"
          >
            地址
          </div>
          <div v-else class="target-fields-title">指标</div>
          <div class="target-fields">
            <div v-if="metrics.length == 0" class="target-fields-placeholder">
              请从右侧拖入字段
            </div>
            <el-tag
              v-else
              v-for="tag in metrics"
              :key="tag"
              closable
              type=""
              @close="handleRemoveMetrics(tag)"
            >
              {{ tag }}
            </el-tag>
          </div>
        </div>
        <div :style="widgetContainerStyle">
          <div :style="widgetContainerInnerStyle">
            <div class="widget-title" @click="handleEditTitle">
              <div :style="titleStyle">
                {{ settings.title.title || "未命名组件" }}
              </div>
              <div><i class="el-icon-edit"></i></div>
            </div>
            <div
              :style="
                'flex: 1; margin-top: 10px;' +
                (settings.event.pointerEvent
                  ? 'pointer-events: all;'
                  : 'pointer-events: none;')
              "
            >
              <dashboard-widget
                v-if="layouted"
                ref="chart"
                :type="type"
                :settings="settings"
                :data="dataset"
                :value="value"
                :readonly="false"
              />
            </div>
          </div>
        </div>
      </div>
      <div id="dataset">
        <div style="padding: 5px 10px; display: flex">
          <div
            style="
              flex: 1;
              font-size: 14px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            "
            :title="model.name"
          >
            {{ model.name }}
          </div>
          <div>
            <el-dropdown-ex
              trigger="click"
              :stopPropagationOnClick="true"
              @command="handleCommand"
            >
              <span class="el-dropdown-link">
                &nbsp;<i class="el-icon-more"></i>&nbsp;
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item :command="makeCommand('realTimeModel')"
                  >实时数据</el-dropdown-item
                >
                <el-dropdown-item :command="makeCommand('aextractModel')"
                  >抽取数据</el-dropdown-item
                >
                <el-dropdown-item :command="makeCommand('refresh')"
                  >刷新数据集</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown-ex>
          </div>
        </div>
        <div style="padding: 5px 10px; display: flex">
          <el-input
            placeholder="搜索字段"
            prefix-icon="el-icon-search"
            v-model="searchKey"
            size="mini"
            clearable
          />
        </div>
        <div v-if="model && model.id" style="padding: 5px 10px">
          <div
            v-for="field in searchFields"
            :key="field.id"
            class="field-container"
          >
            <div
              :class="
                'field-type ' +
                (field.type == 'number' ? 'field-number' : 'field-string')
              "
            >
              {{ field.type == "number" ? "#" : "T" }}
            </div>
            <div
              class="field-text"
              draggable="true"
              @dragstart="handleDragStart(field.name)"
              @dragend="handleDragStop(field.name)"
            >
              {{ field.name + (field.label ? "(" + field.label + ")" : "") }}
            </div>
          </div>
        </div>
        <div
          v-else
          style="margin-top: 20px; display: flex; justify-content: center"
        >
          <el-button-group>
            <el-button
              type="primary"
              plain
              @click="handleCommand('realTimeModel')"
            >
              实时数据</el-button
            >
            <el-button
              type="primary"
              plain
              @click="handleCommand('aextractModel')"
            >
              抽取数据</el-button
            >
          </el-button-group>
        </div>
      </div>
    </div>
    <el-dialog
      title="组件标题"
      :visible.sync="editingTitleVisible"
      width="500px"
      :close-on-click-modal="false"
      append-to-body
    >
      <el-input
        v-model="editingTitle"
        placeholder="请输入组件标题"
        clearable
        autofocus
      />
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="handleEditTitleComplete"
          >确 定</el-button
        >
        <el-button @click="handleEditTitleCancel">取 消</el-button>
      </div>
    </el-dialog>
    <el-dialog
      title="数据处理"
      :visible.sync="editingDataFormatterVisible"
      width="960px"
      :close-on-click-modal="false"
      append-to-body
    >
      <!--<el-input v-model="editingDataFormatter" type="textarea" placeholder="" />-->
      <monaco-editor
        v-model="editingDataFormatter"
        :read-only="false"
        language="javascript"
        :options="{
          fontSize: 14,
          padding: { top: 5, bottom: 5 },
          minimap: { enabled: false },
        }"
        theme="default"
        height="450"
        width="100%"
      />
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="handleEditDataFormatterComplete"
          >确 定</el-button
        >
        <el-button @click="handleEditDataFormatterCancel">取 消</el-button>
      </div>
    </el-dialog>
    <file-upload
      ref="uploadFile"
      type="dialog"
      :title="type == 'image' ? '上传图片' : '上传视频'"
      :placeholder="
        type == 'image'
          ? '请输入图片地址或上传图片'
          : '请输入视频地址或上传视频'
      "
      :accept="type == 'image' ? 'image/png,image/jpeg,image/gif' : 'video/mp4'"
      @change="handleUploadedFile"
    />
    <!-- 选择数据源 -->
    <el-dialog
      title="选择数据源"
      :visible.sync="seldatasourceDialogVisible"
      append-to-body
      width="1100px"
    >
      <div class="data-source">
        <directData-source
          v-if="datasourcetype == '1'"
          :openfrom="'formdesign'"
          @handleSelectModel="handleSelectModel"
        />
        <extractData-source
          v-if="datasourcetype == '2'"
          :openfrom="'formdesign'"
          @handleSelectModel="handleSelectModel"
        />
      </div>
    </el-dialog>
  </div>
</template>

<script>
import MonacoEditor from "@/utils/monaco-editor";
import Widget from "./widgets/index.vue";
import IconSelect from "@/components/IconSelect";
import { getModel, execModel } from "@/api/core/data/datasource/model";
import DirectDataSource from "@/views/core/data/datasource/model/direct";
import ExtractDataSource from "@/views/core/data/datasource/model/extract";

export default {
  name: "dashboard-widget-edit",
  components: {
    MonacoEditor,
    "dashboard-widget": Widget,
    "icon-select": IconSelect,
    DirectDataSource,
    ExtractDataSource,
  },
  props: {
    id: {
      type: [Number, String],
      default: () => "",
    },
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    const settings = this.data.settings
      ? JSON.parse(JSON.stringify(this.data.settings))
      : {
          display: {
            hidden: false,
          },
          padding: {
            left: undefined,
            right: undefined,
            top: undefined,
            bottom: undefined,
          },
          background: {
            color: "",
            image: "",
            imageScale: "auto",
            darkPreview: false,
          },
          title: {
            title: "",
            fontSize: undefined,
            color: "",
            align: "left",
            hidden: true,
          },
          text: {
            color: "",
            fontSize: undefined,
            fontSizeUnit: "px",
            align: "center",
            valign: "middle",
          },
          scrollbar: {
            hidden: false,
          },
          event: {
            pointerEvent: true,
            prefix: "",
            naviUrl: "",
            naviType: "",
            popoverUrl: "",
          },
          image: {
            scale: "auto",
          },
          legend: {
            show: true,
            orient: "horizontal", // vertical
            align: "center",
            valign: "top",
            alignOffset: undefined,
            valignOffset: undefined,
          },
          xAxis: {
            name: "",
            axisLine: {
              show: false,
              lineStyle: {
                color: "#000",
                width: 1,
                type: "solid",
              },
            },
            splitLine: {
              show: false,
              lineStyle: {
                color: "#ccc",
                width: 1,
                type: "solid",
              },
            },
          },
          yAxis: {
            name: "",
            axisLine: {
              show: false,
              lineStyle: {
                color: "#000",
                width: 1,
                type: "solid",
              },
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: "#ccc",
                width: 1,
                type: "solid",
              },
            },
          },
          grid: {
            top: "40",
            left: "2%",
            right: "2%",
            bottom: "2%",
          },
          size: {
            outsideRadius: "60%",
            insideRadius: "45%",
            width: "",
          },
          theme: {
            theme: "",
            color: [
              "#19d4ae",
              "#5ab1ef",
              "#fa6e86",
              "#ffb980",
              "#0067a6",
              "#c4b4e4",
              "#d87a80",
              "#9cbbff",
              "#d9d0c7",
              "#87a997",
              "#d49ea2",
              "#5b4947",
              "#7ba3a8",
            ],
          },
          datav: {
            type: "",
            subType: "",
            reverse: false,
          },
          dataFormatter: undefined,
        };

    if (!settings.display) {
      settings.display = {
        hidden: false,
      };
    }

    if (!settings.legend) {
      settings.legend = {
        show: true,
        orient: "horizontal", // vertical
        align: "center",
        valign: "top",
        alignOffset: undefined,
        valignOffset: undefined,
      };
    }

    if (!settings.xAxis) {
      settings.xAxis = {
        name: "",
        axisLine: {
          show: false,
          lineStyle: {
            color: "#000",
            width: 1,
            type: "solid",
          },
        },
        splitLine: {
          show: false,
          lineStyle: {
            color: "#ccc",
            width: 1,
            type: "solid",
          },
        },
      };
    }

    if (!settings.yAxis) {
      settings.yAxis = {
        name: "",
        axisLine: {
          show: false,
          lineStyle: {
            color: "#000",
            width: 1,
            type: "solid",
          },
        },
        splitLine: {
          show: true,
          lineStyle: {
            color: "#ccc",
            width: 1,
            type: "solid",
          },
        },
      };
    }

    if (!settings.series) {
      settings.series = {
        label: {
          show: false,
          position: "",
        },
      };
    }

    if (!settings.grid) {
      settings.grid = {
        top: "40",
        left: "2%",
        right: "2%",
        bottom: "2%",
      };
    }

    if (!settings.size) {
      settings.size = {
        outsideRadius: "60%",
        insideRadius: "45%",
        width: "60%",
      };
    }

    if (!settings.theme) {
      settings.theme = {
        theme: "",
        color: [
          "#19d4ae",
          "#5ab1ef",
          "#fa6e86",
          "#ffb980",
          "#0067a6",
          "#c4b4e4",
          "#d87a80",
          "#9cbbff",
          "#d9d0c7",
          "#87a997",
          "#d49ea2",
          "#5b4947",
          "#7ba3a8",
        ],
      };
    }

    if (!settings.map) {
      settings.map = {
        map: "china",
        normalLabelShow: true,
        normalLabelColor: undefined,
        normalLabelFontSize: undefined,
        emphasisLabelShow: true,
        emphasisLabelColor: undefined,
        emphasisLabelFontSize: undefined,
        normalAreaColor: undefined,
        normalBorderColor: undefined,
        normalBorderWidth: undefined,
        emphasisAreaColor: undefined,
        emphasisBorderColor: undefined,
        emphasisBorderWidth: undefined,
      };
    }

    if (!settings.prefix) {
      settings.prefix = {};
    }

    if (!settings.prefix.icon) {
      settings.prefix.icon = "";
    }

    if (!settings.suffix) {
      settings.suffix = {};
    }

    if (!settings.suffix.icon) {
      settings.suffix.icon = "";
    }

    if (!settings.unit) {
      settings.unit = {};
    }

    if (!settings.border) {
      settings.border = {};
    }

    if (!settings.table) {
      settings.table = {};
    }

    if (!settings.select) {
      settings.select = {};
    }

    if (!settings.dialog) {
      settings.dialog = {};
    }

    return {
      model: {
        id: this.data.model,
        name: "数据集",
      },
      width: 0,
      height: 0,
      fields: [],
      searchFields: [],
      searchKey: "",
      activeWidgetTypeTab:
        this.data.type == "echarts" || this.data.type == "datav"
          ? "advance"
          : "basic",
      activeSettingTab: "common",
      editingTitle: "",
      editingTitleVisible: false,
      editingDataFormatter: "",
      editingDataFormatterVisible: false,
      settings: settings,
      selectedBorderTemplate: "",
      dataset: [],
      layouted: false,
      type: this.data.type || "",
      typeTip: this.getTipByType(this.data.type || ""),
      value: this.data.value || "",
      value_input: this.data.value || "",
      dimension: [].concat(settings.dimension || []),
      metrics: [].concat(settings.metrics || []),
      datasourcetype: "",
      seldatasourceDialogVisible: false,
    };
  },
  created() {
    this.loadData((ret) => {
      if (ret) {
        this.widgetSettingChange();
      }
    });
  },
  mounted() {
    window.addEventListener("resize", this.onWindowResize);
    this.onWindowResize();
    this.layouted = true;
  },
  destroyed() {
    window.removeEventListener("resize", this.onWindowResize);
  },
  methods: {
    loadData(callback) {
      if (!this.model || !this.model.id) {
        callback && callback(false);
        return;
      }
      const loading = this.$loading({
        lock: true,
        text: "正在加载数据集",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.1)",
      });
      getModel(this.model.id)
        .then((response) => {
          this.model = response.data;
          this.fields = response.data.metas.sort((a, b) => {
            if (a.type == "number" && b.type != "number") {
              return 1;
            } else if (a.type != "number" && b.type == "number") {
              return -1;
            }
            return 0;
          });
          this.searchFields = this.searchKey
            ? this.fields.filter(
                (v) =>
                  v.name.toLowerCase().indexOf(this.searchKey.toLowerCase()) >=
                    0 ||
                  v.label.toLowerCase().indexOf(this.searchKey.toLowerCase()) >=
                    0
              )
            : this.fields;

          const maxCount = 1000;
          execModel(this.model.id, JSON.stringify({ __max_count__: maxCount }))
            .then((response) => {
              loading.close();
              //console.log(response)
              this.dataset = response.data;
              callback && callback(true);
            })
            .catch((err) => {
              loading.close();
              callback && callback(true);
            });
        })
        .catch((e) => {
          loading.close();
          callback && callback(false);
        });
    },
    onWindowResize() {
      this.width = document.documentElement.clientWidth;
      this.height = document.documentElement.clientHeight;
    },
    widgetSettingChange() {
      if (this.dimension.length > 0 || this.metrics.length > 0) {
        this.settings.dimension = this.dimension;
        this.settings.metrics = this.metrics;
        this.settings = { ...this.settings };
      } else {
        delete this.settings.dimension;
        delete this.settings.metrics;
        this.settings = { ...this.settings };
      }
      this.handleSettingSizeChange();
    },
    handleCancel() {
      this.$confirm("是否取消?", "警告", {
        confirmButtonText: "是",
        cancelButtonText: "否",
        type: "warning",
      })
        .then(() => {
          this.$emit("cancel");
        })
        .catch(() => {});
    },
    handleSave() {
      this.$confirm("是否完成?", "警告", {
        confirmButtonText: "是",
        cancelButtonText: "否",
        type: "warning",
      })
        .then(() => {
          const data = {
            version: "1.0",
            title: this.settings.title.title,
            type: this.type,
            value: this.value,
            model: this.model.id,
            settings: this.settings,
          };
          //console.log(JSON.stringify(data))
          this.$emit("complete", this.id, data);
        })
        .catch(() => {});
    },
    handleCommand(cmd) {
      if (cmd == "refresh") {
        this.loadData((ret) => {});
      } else if (cmd == "realTimeModel") {
        this.handleRealTimeDatasource("1");
      } else if (cmd == "aextractModel") {
        this.handleRealTimeDatasource("2");
      }
    },
    makeCommand(cmd) {
      return cmd;
    },
    // 实时 抽取数据
    handleRealTimeDatasource(type) {
      this.datasourcetype = type;
      this.seldatasourceDialogVisible = true;
    },
    // 实时 抽取数据 确定
    handleSelectModel(data) {
      if (this.model && this.model.id == data.id) {
        this.seldatasourceDialogVisible = false;
        return;
      }
      this.model = {
        id: data.id,
        name: "",
      };
      this.loadData((ret) => {
        if (ret) {
          this.dimension = [];
          this.metrics = [];
          this.widgetSettingChange();
        }
      });
      this.seldatasourceDialogVisible = false;
    },
    handleSelectType(type) {
      this.type = type;
      if (type == "bar") {
        this.settings.xAxis.splitLine.show = true;
        this.settings.yAxis.splitLine.show = false;
      } else {
        this.settings.xAxis.splitLine.show = false;
        this.settings.yAxis.splitLine.show = true;
      }
    },
    getTipByType(type) {
      let typeTip = "";
      if (type == "pie") {
        typeTip = "饼图";
      } else if (type == "ring") {
        typeTip = "环形图";
      } else if (type == "line") {
        typeTip = "折线图";
      } else if (type == "area") {
        typeTip = "面积图";
      } else if (type == "histogram") {
        typeTip = "柱状图";
      } else if (type == "bar") {
        typeTip = "条形图";
      } else if (type == "funnel") {
        typeTip = "漏斗图";
      } else if (type == "radar") {
        typeTip = "雷达图";
      } else if (type == "scatter") {
        typeTip = "散点图";
      } else if (type == "wordcloud") {
        typeTip = "词云图";
      } else if (type == "map") {
        typeTip = "地图";
      } else if (type == "kpi") {
        typeTip = "kpi指标卡";
      } else if (type == "text") {
        typeTip = "文本";
      } else if (type == "image") {
        typeTip = "图片";
      } else if (type == "video") {
        typeTip = "视频";
      } else if (type == "web") {
        typeTip = "网页";
      } else if (type == "rank") {
        typeTip = "排行榜";
      } else if (type == "select") {
        typeTip = "选择框";
      } else if (type == "datav") {
        typeTip = "datav";
      } else if (type == "echarts") {
        typeTip = "echarts";
      } else {
        typeTip = "请选择图表";
      }
      return typeTip;
    },
    handleMouseOverType(type) {
      if (type == "pie") {
        this.typeTip = "饼图：1个维度，1个指标";
      } else if (type == "ring") {
        this.typeTip = "环形图：1个维度，1个指标";
      } else if (type == "line") {
        this.typeTip = "折线图：1个维度，多个指标";
      } else if (type == "area") {
        this.typeTip = "面积图：1个维度，多个指标";
      } else if (type == "histogram") {
        this.typeTip = "柱状图：1个维度，多个指标";
      } else if (type == "bar") {
        this.typeTip = "条形图：1个维度，多个指标";
      } else if (type == "funnel") {
        this.typeTip = "漏斗图：1个维度，1个指标";
      } else if (type == "radar") {
        this.typeTip = "雷达图：1个维度，1个指标";
      } else if (type == "scatter") {
        this.typeTip = "散点图：1个维度，多个指标";
      } else if (type == "wordcloud") {
        this.typeTip = "词云图：1个维度，1个指标";
      } else if (type == "map") {
        this.typeTip = "地图：1个维度，多个指标";
      } else if (type == "kpi") {
        this.typeTip = "kpi指标卡：0个维度，1个指标";
      } else if (type == "text") {
        this.typeTip = "文本";
      } else if (type == "image") {
        this.typeTip = "图片";
      } else if (type == "video") {
        this.typeTip = "视频";
      } else if (type == "web") {
        this.typeTip = "网页";
      } else if (type == "rank") {
        this.typeTip = "排行榜：多个维度，0个指标";
      } else if (type == "select") {
        this.typeTip = "选择框";
      } else if (type == "datav") {
        this.typeTip = "datav";
      } else if (type == "echarts") {
        this.typeTip = "echarts";
      }
    },
    handleMouseLeaveType(type) {
      this.typeTip = this.getTipByType(this.type);
    },
    addClass(ele, classz) {
      if (!ele) {
        return;
      }
      const cls = ele.getAttribute("class");
      const sps = cls.split(" ");
      if (sps.indexOf(classz) < 0) {
        ele.setAttribute("class", cls + " " + classz);
      }
    },
    removeClass(ele, classz) {
      if (!ele) {
        return;
      }
      const cls = ele.getAttribute("class");
      const sps = cls.split(" ");
      const idx = sps.indexOf(classz);
      if (idx >= 0) {
        sps.splice(idx, 1);
        ele.setAttribute("class", sps.join(" "));
      }
    },
    handleSelectBorderTemplate(value) {
      if (value == "border1") {
        this.settings.border = {
          left: "5.5rem",
          right: "1.583rem",
          top: "2.125rem",
          bottom: "0.875rem",
          image: "images/border/border/border1.png",
          imageSliceLeft: "132",
          imageSliceRight: "38",
          imageSliceTop: "51",
          imageSliceBottom: "21",
          imageRepeat: "stretch",
        };
      }
    },
    handleSelectPrefixIcon(icon) {
      this.settings.prefix.icon = icon;
    },
    handleIconClickPrefix() {
      this.settings.prefix.icon = "";
    },
    handleSelectSuffixIcon(icon) {
      this.settings.suffix.icon = icon;
    },
    handleIconClickSuffix() {
      this.settings.suffix.icon = "";
    },
    handleSettingSizeChange() {
      setTimeout(() => {
        if (this.$refs.chart) {
          this.$refs.chart.resize();
        }
      }, 1);
    },
    handleSettingTitleSizeChange() {
      this.handleSettingTitleChange();
      this.handleSettingSizeChange();
    },
    handleSettingTitleChange() {
      this.handleSettingChange();
    },
    handleSettingTextChange() {
      this.handleSettingChange();
    },
    handleSettingDataVTypeChange() {
      this.settings.datav.subType = "";
    },
    handleSettingThemeChange() {
      this.handleSettingChange();
    },
    handleSettingSeriesFormatterChange() {
      if (!this.settings.series.label.formatter) {
        this.settings.series.label.formatter = undefined;
      }
      console.log(this.settings.series.label);
    },
    handleSettingChange() {
      this.settings = { ...this.settings };
    },
    handleDragStart(fieldName) {
      event.dataTransfer.setData("field-text", fieldName);
      //event.dataTransfer.effectAllowed = "copyMove";
      //event.dataTransfer.dropEffect = "copy";
      this.addClass(this.$refs.dimension, "target-fields-container-draging");
      this.addClass(this.$refs.metrics, "target-fields-container-draging");
    },
    handleDragStop(fieldName) {
      this.removeClass(this.$refs.dimension, "target-fields-container-draging");
      this.removeClass(this.$refs.metrics, "target-fields-container-draging");
    },
    handleDragOver(eleId) {
      event.preventDefault();
      this.addClass(this.$refs[eleId], "target-fields-container-dragingover");
    },
    handleDragLeave(eleId) {
      this.removeClass(
        this.$refs[eleId],
        "target-fields-container-dragingover"
      );
    },
    handleDrop(eleId) {
      event.preventDefault();
      this.removeClass(
        this.$refs[eleId],
        "target-fields-container-dragingover"
      );
      const data = event.dataTransfer.getData("field-text");
      if (data) {
        if (eleId == "dimension") {
          const idx = this.dimension.indexOf(data);
          if (idx < 0) {
            this.dimension.push(data);
            this.widgetSettingChange();
          }
        } else if (eleId == "metrics") {
          const idx = this.metrics.indexOf(data);
          if (idx < 0) {
            this.metrics.push(data);
            this.widgetSettingChange();
          }
        }
      }
    },
    handleRemoveDimensions(dimension) {
      const idx = this.dimension.indexOf(dimension);
      if (idx >= 0) {
        this.$confirm("确定移除" + dimension + "吗?", "警告", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.dimension.splice(idx, 1);
            this.widgetSettingChange();
          })
          .catch(() => {});
      }
    },
    handleRemoveMetrics(metrics) {
      const idx = this.metrics.indexOf(metrics);
      if (idx >= 0) {
        this.$confirm("确定移除" + metrics + "吗?", "警告", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.metrics.splice(idx, 1);
            this.widgetSettingChange();
          })
          .catch(() => {});
      }
    },
    handleUploadFile() {
      this.$refs.uploadFile.open("");
    },
    handleUploadedFile(value) {
      this.value_input = value;
      this.value = value;
    },
    handleValueChange() {
      this.value = this.value_input;
    },
    handleEditTitle() {
      this.editingTitle = this.settings.title.title;
      this.editingTitleVisible = true;
    },
    handleEditTitleComplete() {
      this.editingTitleVisible = false;
      this.settings.title.title = this.editingTitle;
      this.settings = { ...this.settings };
    },
    handleEditTitleCancel() {
      this.editingTitleVisible = false;
    },
    handleEditDataFormatter() {
      this.editingDataFormatter = this.settings.dataFormatter;
      if (!this.editingDataFormatter) {
        if (this.type == "text" || this.type == "kpi") {
          this.editingDataFormatter =
            "function (data, settings) {\r\n    if (settings.metrics && settings.metrics.length > 0 && data && data.length > 0) {\r\n        return data[0][settings.metrics[0]]\r\n    }\r\n}";
        } else {
          this.editingDataFormatter =
            "function (data, settings) {\r\n    var option = {\r\n    }\r\n    return option\r\n}";
        }
      }
      this.editingDataFormatterVisible = true;
    },
    handleEditDataFormatterComplete() {
      this.editingDataFormatterVisible = false;
      this.settings.dataFormatter = this.editingDataFormatter;
      this.settings = { ...this.settings };
    },
    handleEditDataFormatterCancel() {
      this.editingDataFormatterVisible = false;
    },
  },
  watch: {
    searchKey(v) {
      this.searchFields = this.searchKey
        ? this.fields.filter(
            (v) =>
              v.name.toLowerCase().indexOf(this.searchKey.toLowerCase()) >= 0 ||
              v.label.toLowerCase().indexOf(this.searchKey.toLowerCase()) >= 0
          )
        : this.fields;
    },
  },
  computed: {
    widgetContainerStyle() {
      let style =
        "display: flex; flex-direction: column; flex: 1; position: relative;";
      style +=
        "padding-left: " +
        (this.settings.padding.left !== undefined
          ? this.settings.padding.left
          : 10) +
        "px;";
      style +=
        "padding-right: " +
        (this.settings.padding.right !== undefined
          ? this.settings.padding.right
          : 10) +
        "px;";
      style +=
        "padding-top: " +
        (this.settings.padding.top !== undefined
          ? this.settings.padding.top
          : 10) +
        "px;";
      style +=
        "padding-bottom: " +
        (this.settings.padding.bottom !== undefined
          ? this.settings.padding.bottom
          : 10) +
        "px;";
      if (this.settings.background.darkPreview) {
        style += "background-color: #000;";
      } else {
        if (this.settings.background.color) {
          style += "background-color: " + this.settings.background.color + ";";
        } else {
          style += "background-color: #fff;";
        }
      }
      if (this.settings.background.image) {
        style +=
          "background-image: url(" +
          this.settings.background.image +
          ");background-repeat: no-repeat;";
        if (this.settings.background.imageScale == "auto") {
          style += "background-size: 100% 100%;";
        } else if (this.settings.background.imageScale == "cover") {
          style += "background-size: cover;";
        }
      }
      const border = this.settings.border;
      if (border) {
        const numReg = /^[0-9]+$/;
        style += "border-style: solid;";
        if (border.color) {
          style += "border-color: " + border.color + ";";
        }
        if (border.left) {
          if (numReg.test(border.left)) {
            style += "border-left-width: " + border.left + "px;";
          } else {
            style += "border-left-width: " + border.left + ";";
          }
        } else {
          style += "border-left-width: 0px;";
        }
        if (border.right) {
          if (numReg.test(border.right)) {
            style += "border-right-width: " + border.right + "px;";
          } else {
            style += "border-right-width: " + border.right + ";";
          }
        } else {
          style += "border-right-width: 0px;";
        }
        if (border.top) {
          if (numReg.test(border.top)) {
            style += "border-top-width: " + border.top + "px;";
          } else {
            style += "border-top-width: " + border.top + ";";
          }
        } else {
          style += "border-top-width: 0px;";
        }
        if (border.bottom) {
          if (numReg.test(border.bottom)) {
            style += "border-bottom-width: " + border.bottom + "px;";
          } else {
            style += "border-bottom-width: " + border.bottom + ";";
          }
        } else {
          style += "border-bottom-width: 0px;";
        }
        if (border.image) {
          style += "border-image-source: url(" + border.image + ");";
        }
        if (
          border.imageSliceLeft ||
          border.imageSliceRight ||
          border.imageSliceTop ||
          border.imageSliceBottom
        ) {
          style +=
            "border-image-slice: " +
            (border.imageSliceTop || border.imageSliceBottom || 0) +
            " " +
            (border.imageSliceRight || border.imageSliceLeft || 0) +
            " " +
            (border.imageSliceBottom || border.imageSliceTop || 0) +
            " " +
            (border.imageSliceLeft || border.imageSliceRight || 0) +
            ";";
        }
        if (border.imageRepeat) {
          style += "border-image-repeat: " + border.imageRepeat + ";";
        }
        if (border.radius) {
          if (numReg.test(border.radius)) {
            style += "border-radius: " + border.radius + "px;";
          } else {
            style += "border-radius: " + border.radius + ";";
          }
        }
      }
      // 大小必须指定像素，不然会有被组件撑开的情况
      style +=
        "width: " +
        (this.width - 460) +
        "px; height: " +
        (this.height - 160) +
        "px;";
      return style;
    },
    widgetContainerInnerStyle() {
      let style =
        "display: flex; flex-direction: column; flex: 1; overflow: hidden; position: relative;";
      const border = this.settings.border;
      if (border && (border.color || border.image)) {
        const numReg = /^[0-9]+$/;
        if (border.left) {
          if (numReg.test(border.left)) {
            style += "margin-left: -" + border.left + "px;";
          } else {
            style += "margin-left: -" + border.left + ";";
          }
        }
        if (border.right) {
          if (numReg.test(border.right)) {
            style += "margin-right: -" + border.right + "px;";
          } else {
            style += "margin-right: -" + border.right + ";";
          }
        }
        if (border.top) {
          if (numReg.test(border.top)) {
            style += "margin-top: -" + border.top + "px;";
          } else {
            style += "margin-top: -" + border.top + ";";
          }
        }
        if (border.bottom) {
          if (numReg.test(border.bottom)) {
            style += "margin-bottom: -" + border.bottom + "px;";
          } else {
            style += "margin-bottom: -" + border.bottom + ";";
          }
        }
      }
      return style;
    },
    titleStyle() {
      let style = "flex: 1;";
      style += "font-size:" + (this.settings.title.fontSize || 18) + "px;";
      style += "color:" + (this.settings.title.color || "#000") + ";";
      style += "text-align:" + this.settings.title.align + ";";
      if (this.settings.title.bold) {
        style += "font-weight: bold;";
      }
      if (this.settings.title.showSplitLine) {
        style += "padding-bottom: " + (this.settings.title.splitLineMargin || 10) + "px; border-bottom: " + (this.settings.title.splitLineStyle || 'solid') + " " + (this.settings.title.splitLineSize || 1) + "px " + (this.settings.title.splitLineColor || '#eee') + ";"
      }
      return style;
    },
    showDatavReverseConfig() {
      const type = this.settings.datav.subType;
      if (
        type == "dv-border-box-4" ||
        type == "dv-border-box-5" ||
        type == "dv-border-box-8" ||
        type == "dv-decoration-2" ||
        type == "dv-decoration-4" ||
        type == "dv-decoration-8"
      ) {
        return true;
      }
      return false;
    },
  },
};
</script>

<style scoped>
::-webkit-scrollbar {
  width: 8px;
}
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset006pxrgba(0, 0, 0, 0.3);
  border-radius: 8px;
}
::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background: rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: inset006pxrgba(0, 0, 0, 0.5);
}
::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(0, 0, 0, 0.1);
}
::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.2);
}
#widget-edit-container {
  position: relative;
  background-color: #f5f5f5;
}
#top-bar {
  height: 40px;
  background-color: #fff;
  border-bottom: 1px solid #f5f5f5;
  padding: 0px 10px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
#dataset {
  width: 220px;
  height: 100%;
  background-color: #fff;
  border-right: solid 1px #eee;
}
#attr {
  width: 220px;
  height: 100%;
  background-color: #fff;
  position: relative;
  border-right: solid 1px #eee;
  display: flex;
  flex-direction: column;
}
.el-dropdown-link:hover {
  cursor: pointer;
}
.field-container {
  user-select: none;
  display: flex;
  align-items: center;
}
.field-text {
  margin-left: 5px;
  padding: 3px 3px;
  flex: 1;
  font-size: 14px;
}
.field-text:hover {
  background-color: #f5f5f5;
  cursor: pointer;
}
.field-string {
  color: lightskyblue;
}
.field-number {
  color: green;
}
.chart-types-container {
  padding-left: 10px;
  padding-right: 0px;
  height: 120px;
}
.chart-types {
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  overflow: auto;
  position: relative;
}
.chart-type {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 35px;
  margin-right: 5px;
  margin-bottom: 5px;
}
.chart-type-selected {
  border: solid 1px #1890ff;
}
.chart-type:hover {
  border: solid 1px #1890ff;
}
.chart-type img {
  width: 30px;
  height: 30px;
}
.chart-type-tip {
  padding: 5px 10px;
  font-size: 13px;
  color: #999;
}
.tabs {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  /*flex: 1;*/
}
.tabs-header {
  height: 40px;
  background-color: #f2f4f7;
  display: flex;
}
.tabs-header-item {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 15px;
  font-size: 13px;
  color: #333;
  font-weight: bold;
  cursor: pointer;
}
.tabs-header-item-active {
  background-color: #fff;
  border-top: solid 2px #1890ff;
  border-bottom: solid 2px #fff;
  color: #1890ff;
}
.tabs-header-item:hover {
  color: #1890ff;
}
.tabs-body {
  overflow: auto;
}
.tabs-panel {
  height: 100%;
  padding: 5px 0px;
}
.collapse-title {
  margin-left: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.collapse-content {
  padding-left: 10px;
  padding-right: 10px;
}
.target-fields-container {
  display: flex;
  background-color: #fff;
  border: solid 1px rgba(0, 0, 0, 0);
  height: 40px;
}
.target-fields-container-draging {
  border: dashed 1px #1890ff;
}
.target-fields-container-dragingover {
  border: solid 1px #1890ff;
}
.target-fields-title {
  padding: 5px 10px;
  font-size: 14px;
  border-right: solid 1px #eee;
  display: flex;
  align-items: center;
}
.target-fields {
  flex: 1;
  padding: 5px 10px;
  font-size: 14px;
  display: flex;
  align-items: center;
  user-select: none;
}
.target-fields-placeholder {
  color: #999;
}
.widget-title {
  border: solid 1px rgba(0, 0, 0, 0);
  padding: 5px 5px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.widget-title:hover {
  cursor: pointer;
  border: solid 1px #1890ff;
}
.widget-title .el-icon-edit {
  margin-left: 5px;
  color: #1890ff;
  visibility: hidden;
}
.widget-title:hover .el-icon-edit {
  visibility: visible;
}
input::-webkit-input-placeholder {
  color: #999;
}
.el-collapse {
  border-top: none;
  border-bottom: none;
}
</style>
<style>
.input-with-select .el-input-group__prepend {
  background-color: #fff;
}
</style>
