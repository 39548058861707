<template>
  <div id="dashboard-container">
    <div v-if="!readonly" id="top-bar"  @click="handleClick">
      <div style="width: 220px">
        <el-radio-group v-model="layoutType" size="mini" @change="handleLayoutTypeChange">
          <el-radio-button label="pc">PC端布局</el-radio-button>
          <el-radio-button label="mobile">移动端布局</el-radio-button>
        </el-radio-group>
      </div>
      <div style="flex: 1; display: flex; align-items: center;">
        <el-button type="text" icon="el-icon-plus" @click.stop="handleAdd">{{childrenDesign ? '添加子组件' : '添加组件'}}</el-button>
        <div style="height: 20px; width: 1px; background-color: #ddd; margin: 0px 12px;"></div>
        <el-button type="text" icon="el-icon-edit" @click.stop="handleItemEdit(selectedItem)" :style="selectedItem ? 'color: #333' : 'color: #bbb'" :disabled="!selectedItem">编辑</el-button>
        <div style="margin: 0px 5px;"></div>
        <el-button type="text" icon="el-icon-copy-document" @click.stop="handleItemCopy(selectedItem)" :style="selectedItem ? 'color: #333' : 'color: #bbb'" :disabled="!selectedItem">复制</el-button>
        <div style="margin: 0px 5px;"></div>
        <el-button type="text" icon="el-icon-document-copy" @click.stop="handleItemPasteConfirm(selectedItem)" :style="copyWidget ? 'color: #333' : 'color: #bbb'" :disabled="!copyWidget">粘贴</el-button>
        <div style="margin: 0px 5px;"></div>
        <el-button type="text" icon="el-icon-delete" @click.stop="handleItemDel(selectedItem)" :style="selectedItem ? 'color: #333' : 'color: #bbb'" :disabled="!selectedItem">删除</el-button>
        <el-button v-if="childrenDesign" type="warning" icon="el-icon-menu" size="mini" @click.stop="childrenDesign=undefined">退出子布局</el-button>
        <el-button v-else-if="selectedItem && selectedItem.d && selectedItem.d.type != 'kpi' && selectedItem.d.type != 'text' && selectedItem.d.type != 'select'" type="default" icon="el-icon-menu" size="mini" @click.stop="handleItemChildrenDesign(selectedItem)">子布局</el-button>
      </div>
      <div>
        <el-button type="default" size="mini" @click.stop="handlePreview">预览</el-button>
        <el-button type="primary" size="mini" :disabled="!change" @click.stop="handleSave">保存</el-button>
      </div>
    </div>
    <div id="dashbard-design-container" :style="'height: ' + (height - (readonly ? 0 : 40)) + 'px'">
      <div v-if="!readonly" id="left-bar">
        <div class="tabs">
          <div class="tabs-header">
            <div :class="activeTab=='widget' ? 'tabs-header-item tabs-header-item-active' : 'tabs-header-item'" @click="() => activeTab = 'widget'">组件</div>
            <div :class="activeTab=='style' ? 'tabs-header-item tabs-header-item-active' : 'tabs-header-item'" @click="() => activeTab = 'style'">属性</div>
            <div :class="activeTab=='layout' ? 'tabs-header-item tabs-header-item-active' : 'tabs-header-item'" @click="() => activeTab = 'layout'">布局</div>
            <div :class="activeTab=='help' ? 'tabs-header-item tabs-header-item-active' : 'tabs-header-item'" @click="() => activeTab = 'help'">帮助</div>
          </div>
          <div class="tabs-body" :style="'height:' + (height - 80) + 'px;'">
            <div class="tabs-panel" :style="activeTab=='widget' ? '' : 'display: none;'">
              <el-tree ref="tree"
                :data="layout"
                :props="defaultProps"
                node-key="i"
                :highlight-current="true"
                :expand-on-click-node="false"
              >
                <div 
                  slot-scope="{ node, data }"
                  :class="
                    data.children
                      ? 'custom-tree-node tree-node-root'
                      : 'custom-tree-node tree-node-leaf'
                  "
                  @mouseover="handleMouseOverTreeItem(data)"
                  @mouseleave="handleMouseLeaveTreeItem(data)"
                  @click="handleClickTreeItem(data)"
                  @dblclick="handleDblClickTreeItem(data)"
                >
                  <div :style="data.d.settings.display && data.d.settings.display.hidden ? 'color: #ccc' : ''" class="el-tree-node__label custom-tree-node-label" :title="data.d && data.d.title || '未命名组件'">{{data.d && data.d.title || '未命名组件'}}</div>
                  <div v-if="!readonly" class="custom-tree-node-button">
                    <el-dropdown-ex trigger="click" :stopPropagationOnClick="true" @command="handleCommand" @visible-change="(e) => handleDropdownVisibleChange(e, data)">
                      <span class="el-dropdown-link">
                        <i class="el-icon-more"></i>&nbsp;
                      </span>
                      <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item icon="el-icon-edit" :command="makeCommand('edit', data)">编辑</el-dropdown-item>
                        <el-dropdown-item icon="el-icon-copy-document" :command="makeCommand('copy', data)">复制</el-dropdown-item>
                        <el-dropdown-item v-if="copyWidget" icon="el-icon-document-copy" :command="makeCommand('paste', data)">粘贴</el-dropdown-item>
                        <el-dropdown-item icon="el-icon-delete" :command="makeCommand('delete', data)">删除</el-dropdown-item>
                        <el-dropdown-item icon="el-icon-top" :command="makeCommand('top', data)" divided>置顶</el-dropdown-item>
                        <el-dropdown-item icon="el-icon-bottom" :command="makeCommand('bottom', data)">置底</el-dropdown-item>
                      </el-dropdown-menu>
                    </el-dropdown-ex>
                  </div>
                </div>
              </el-tree>
            </div>
            <div class="tabs-panel" :style="activeTab=='style' ? '' : 'display: none;'">
              <el-collapse style="border-top: none; border-bottom: none;">
                <el-collapse-item title="仪表板" name="background">
                  <template slot="title">
                    <div class="collapse-title">仪表板</div>
                  </template>
                  <div class="collapse-content">
                    <el-form label-position="top" label-width="80px" size="mini">
                      <el-form-item label="背景颜色">
                        <el-color-picker v-model="settings.dashboard.backgroundColor" @change="handleSettingChange"></el-color-picker>
                      </el-form-item>
                      <el-form-item label="背景图片">
                        <file-upload v-model="settings.dashboard.backgroundImage"
                          type="form"
                          title="背景图片"
                          placeholder="背景图片" 
                          accept="image/png,image/jpeg"
                          @change="handleSettingChange"
                        />
                      </el-form-item>
                      <el-form-item label="背景图片宽度">
                        <el-input v-model="settings.dashboard.backgroundImageWidth" @change="handleSettingChange" />
                      </el-form-item>
                      <el-form-item label="背景图片高度">
                        <el-input v-model="settings.dashboard.backgroundImageHeight" @change="handleSettingChange" />
                      </el-form-item>
                      <el-form-item label="背景图片缩放">
                        <el-select v-model="settings.dashboard.backgroundImageScale" @change="handleSettingChange">
                          <el-option label="不缩放" value=""></el-option>
                          <el-option label="自适应" value="auto"></el-option>
                          <el-option label="等比例缩放" value="cover"></el-option>
                        </el-select>
                      </el-form-item>
                      <el-form-item label="背景图片配色">
                        <el-color-picker v-model="settings.dashboard.backgroundImageMaskColor" @change="handleSettingChange" show-alpha></el-color-picker>
                      </el-form-item>
                    </el-form>
                  </div>
                </el-collapse-item>
                <el-collapse-item title="组件" name="widget">
                  <template slot="title">
                    <div class="collapse-title">组件</div>
                  </template>
                  <div class="collapse-content">
                    <el-form label-position="top" label-width="80px" size="mini">
                      <el-form-item label="默认背景颜色">
                        <el-color-picker v-model="settings.widget.backgroundColor" show-alpha @change="handleSettingChange"></el-color-picker>
                      </el-form-item>
                      <el-form-item label="默认标题颜色">
                        <el-color-picker v-model="settings.widget.titleColor" @change="handleSettingChange"></el-color-picker>
                      </el-form-item>
                      <el-form-item label="默认标题字体大小">
                        <el-input-number v-model="settings.widget.titleFontSize" controls-position="right" :min="10" :max="100" @change="handleSettingTextSizeChange" />
                      </el-form-item>
                      <el-form-item label="默认文本颜色">
                        <el-color-picker v-model="settings.widget.textColor" @change="handleSettingTextColorChange"></el-color-picker>
                      </el-form-item>
                      <el-form-item label="默认文本字体大小">
                        <el-input-number v-model="settings.widget.textFontSize" controls-position="right" :min="10" :max="100" @change="handleSettingTextSizeChange" />
                      </el-form-item>
                    </el-form>
                  </div>
                </el-collapse-item>
                <el-collapse-item title="数据" name="data">
                  <template slot="title">
                    <div class="collapse-title">数据</div>
                  </template>
                  <div class="collapse-content">
                    <el-form label-position="top" label-width="80px" size="mini">
                      <el-form-item label="刷新频率（秒）">
                        <el-input-number v-model="settings.refreshDataDuration" controls-position="right" :min="0" @change="handleRefreshDataDurationChange" />
                      </el-form-item>
                    </el-form>
                  </div>
                </el-collapse-item>
              </el-collapse>
            </div>
            <div class="tabs-panel" :style="activeTab=='layout' ? '' : 'display: none;'">
              <el-collapse style="border-top: none; border-bottom: none;">
                <el-collapse-item title="主布局" name="widget">
                  <template slot="title">
                    <div class="collapse-title">主布局</div>
                  </template>
                  <div class="collapse-content">
                    <el-form label-position="top" label-width="80px" size="mini">
                      <el-form-item label="行数">
                        <el-input-number v-model="settings.grid.rows" controls-position="right" :min="1" :max="200" @change="handleSettingGridChange" />
                      </el-form-item>
                      <el-form-item label="列数">
                        <el-input-number v-model="settings.grid.cols" controls-position="right" :min="1" :max="200" @change="handleSettingGridChange" />
                      </el-form-item>
                      <el-form-item label="允许重叠">
                        <el-select v-model="settings.grid.overlap" placeholder="请选择" @change="handleSettingGridChange">
                          <el-option
                            label="是"
                            :value="true">
                          </el-option>
                          <el-option
                            label="否"
                            :value="false">
                          </el-option>
                        </el-select>
                      </el-form-item>
                      <el-form-item label="纵向自动布局">
                        <el-select v-model="settings.grid.vcompact" placeholder="请选择" @change="handleSettingGridChange">
                          <el-option
                            label="是"
                            :value="true">
                          </el-option>
                          <el-option
                            label="否"
                            :value="false">
                          </el-option>
                        </el-select>
                      </el-form-item>
                      <el-form-item label="组件外边距（像素）">
                        <el-input-number v-model="settings.grid.margin" controls-position="right" :min="0" :max="30" @change="handleSettingSizeChange" />
                      </el-form-item>
                      <el-form-item label="组件内边距（像素）">
                        <el-input-number v-model="settings.grid.padding" controls-position="right" :min="0" :max="30" @change="handleSettingSizeChange" />
                      </el-form-item>
                    </el-form>
                  </div>
                </el-collapse-item>
                <el-collapse-item v-if="childrenDesign && childrenDesign.settings" title="子布局" name="swidget">
                  <template slot="title">
                    <div class="collapse-title" :title="childrenDesign.d.title || '未命名组件'">{{'子布局 - ' + (childrenDesign.d.title || '未命名组件')}}</div>
                  </template>
                  <div class="collapse-content">
                    <el-form label-position="top" label-width="80px" size="mini">
                      <el-form-item label="行数">
                        <el-input-number v-model="childrenDesign.settings.grid.rows" controls-position="right" :min="1" :max="200" @change="handleChildrenSettingGridChange" />
                      </el-form-item>
                      <el-form-item label="列数">
                        <el-input-number v-model="childrenDesign.settings.grid.cols" controls-position="right" :min="1" :max="200" @change="handleChildrenSettingGridChange" />
                      </el-form-item>
                      <el-form-item label="允许重叠">
                        <el-select v-model="childrenDesign.settings.grid.overlap" placeholder="请选择" @change="handleSettingGridChange">
                          <el-option
                            label="是"
                            :value="true">
                          </el-option>
                          <el-option
                            label="否"
                            :value="false">
                          </el-option>
                        </el-select>
                      </el-form-item>
                      <el-form-item label="纵向自动布局">
                        <el-select v-model="childrenDesign.settings.grid.vcompact" placeholder="请选择" @change="handleChildrenSettingGridChange">
                          <el-option
                            label="是"
                            :value="true">
                          </el-option>
                          <el-option
                            label="否"
                            :value="false">
                          </el-option>
                        </el-select>
                      </el-form-item>
                      <el-form-item label="组件外边距（像素）">
                        <el-input-number v-model="childrenDesign.settings.grid.margin" controls-position="right" :min="0" :max="30" @change="handleChildrenSettingSizeChange" />
                      </el-form-item>
                      <el-form-item label="组件内边距（像素）">
                        <el-input-number v-model="childrenDesign.settings.grid.padding" controls-position="right" :min="0" :max="30" @change="handleChildrenSettingSizeChange" />
                      </el-form-item>
                    </el-form>
                  </div>
                </el-collapse-item>
              </el-collapse>
            </div>
            <div class="tabs-panel" :style="activeTab=='help' ? '' : 'display: none;'">
              <el-collapse style="border-top: none; border-bottom: none;">
                <el-collapse-item title="快捷键" name="widget">
                  <template slot="title">
                    <div class="collapse-title">快捷键</div>
                  </template>
                  <div class="collapse-content">
                    <el-form label-position="top" label-width="80px" size="mini">
                      <el-form-item label="编辑组件">
                        <el-input value="选中组件enter或双击" readonly />
                      </el-form-item>
                      <el-form-item label="复制组件">
                        <el-input value="选中组件ctrl+c" readonly />
                      </el-form-item>
                      <el-form-item label="粘贴组件">
                        <el-input value="ctrl+v" readonly />
                      </el-form-item>
                      <el-form-item label="删除组件">
                        <el-input value="选中组件delete" readonly />
                      </el-form-item>
                      <el-form-item label="复制样式">
                        <el-input value="选中组件alt+c" readonly />
                      </el-form-item>
                      <el-form-item label="粘贴样式">
                        <el-input value="选中组件alt+v" readonly />
                      </el-form-item>
                    </el-form>
                  </div>
                </el-collapse-item>
              </el-collapse>
            </div>
          </div>
        </div>
      </div>
      <div id="dashbard-layout" @click="handleClick">
        <div :style="layoutBackgroundStyle">
          <div id="dashbard-layout-container" :style="layoutContainerStyle">
            <grid-layout ref="gridLayout"
              :style="'min-height: ' + (layoutType == 'pc' ? (height - 40) : 720) + 'px'"
              :layout.sync="layout"
              :col-num="settings.grid.cols"
              :row-height="rowHeight"
              :margin="[settings.grid.margin, settings.grid.margin]"
              :is-draggable="!readonly && !childrenDesign ? true : false"
              :is-resizable="!readonly && !childrenDesign ? true : false"
              :vertical-compact="settings.grid.vcompact"
              :prevent-collision="!settings.grid.vcompact"
              :prevent-overlap="!settings.grid.overlap"
              :use-css-transforms="true"
              @layout-updated="handleLayoutUpdated"
            >
              <template v-for="item in layout">
                <grid-item v-if="!item.d.settings.display || !item.d.settings.display.hidden"
                  :key="item.i"
                  :x="item.x"
                  :y="item.y"
                  :w="item.w"
                  :h="item.h"
                  :i="item.i"
                  :selected="selectedItem && selectedItem.i == item.i"
                  :style="widgetContainerStyle(item)"
                  drag-ignore-from=".item-no-drag"
                  @resize="handleItemResize"
                  @resized="handleItemResized"
                  @move="handleItemMove"
                  @moved="handleItemMoved"
                >
                  <div ref="items" :class="'item-container ' + (!readonly && !childrenDesign ? (selectedItem && selectedItem.i == item.i ? 'item-selected' : 'item-normal') : '')" :style="widgetStyle(item)" @click.stop="handleItemClick(item)" @dblclick.stop="handleItemDblClick(item)">
                    <div :class="selectedItem && selectedItem.i == item.i ? 'item-drag' : 'item-no-drag'" :style="widgetInnerStyle(item)">
                      <div :style="widgetInnerInnerStyle(item)">
                        <div v-if="item.d.title" class="item-title" :style="widgetTitleStyle(item)">
                          <div>{{item.d.title}}</div>
                        </div>
                        <div v-if="item.h > 1 || !item.d.title || (item.d.settings && item.d.settings.title && item.d.settings.title.hidden)" :data-key="item.i" ref="itemBody" class="item-body">
                          <dashboard-widget v-if="layouted[item.i]" 
                            ref="charts" 
                            :title="item.d.title"
                            :type="item.d.type" 
                            :params="params"
                            :data="modelDatas[item.d.model]" 
                            :value="item.d.value"
                            :settings="item.d && item.d.settings"
                            :defaultSettings="settings.widget"
                            :readonly="readonly"
                            :data-key="item.i"
                            @message="handleMessage"
                          />
                          <grid-layout v-if="item.children && item.settings && item.settings.grid.rowHeight && item.settings.grid.rowHeight > 0" 
                            :layout.sync="item.children" 
                            :style="'position: absolute; top: 0px; left: 0px; width: 100%; height: 100%;' + (childrenDesign ? '' : 'pointer-events: none;')"
                            :col-num="item.settings.grid.cols"
                            :row-height="item.settings.grid.rowHeight"
                            :max-rows="item.settings.grid.rows"
                            :margin="[item.settings.grid.margin, item.settings.grid.margin]"
                            :is-draggable="!readonly && childrenDesign && childrenDesign.i == item.i ? true : false"
                            :is-resizable="!readonly && childrenDesign && childrenDesign.i == item.i ? true : false"
                            :vertical-compact="item.settings.grid.vcompact"
                            :prevent-collision="!item.settings.grid.vcompact"
                            :prevent-overlap="!item.settings.grid.overlap"
                            :use-css-transforms="true"
                            @layout-updated="handleSLayoutUpdated"
                          >
                            <template v-for="sitem in item.children">
                              <grid-item v-if="!sitem.d.settings.display || !sitem.d.settings.display.hidden"
                                :key="sitem.i"
                                :x="sitem.x"
                                :y="sitem.y"
                                :w="sitem.w"
                                :h="sitem.h"
                                :i="sitem.i"
                                :selected="selectedItem && selectedItem.i == sitem.i"
                                drag-ignore-from=".sitem-no-drag"
                                @resize="handleSItemResize"
                                @resized="handleSItemResized"
                                @move="handleSItemMove"
                                @moved="handleSItemMoved"
                              >
                                <div v-if="childrenDesign" ref="sitems" :class="'sitem-container ' + (!readonly && childrenDesign && childrenDesign.i == item.i ? (selectedItem && selectedItem.i == sitem.i ? 'sitem-selected' : 'sitem-normal') : '')" :style="widgetStyle(sitem)" @click.stop="handleSItemClick(sitem)" @dblclick.stop="handleSItemDblClick(sitem)">
                                  <div :class="selectedItem && selectedItem.i == sitem.i ? 'sitem-drag' : 'sitem-no-drag'" :style="widgetInnerStyle(sitem)">
                                    <div :style="widgetInnerInnerStyle(sitem)">
                                      <div v-if="sitem.d.title" class="item-title" :style="widgetTitleStyle(sitem)">
                                        <div>{{sitem.d.title}}</div>
                                      </div>
                                      <div v-if="sitem.h > 1 || !sitem.d.title || (sitem.d.settings && sitem.d.settings.title && sitem.d.settings.title.hidden)" :data-key="sitem.i" ref="sitemBody" class="item-body">
                                        <dashboard-widget v-if="layouted[sitem.i]" 
                                          ref="scharts" 
                                          :title="sitem.d.title"
                                          :type="sitem.d.type" 
                                          :params="params"
                                          :data="modelDatas[sitem.d.model]" 
                                          :value="sitem.d.value"
                                          :settings="sitem.d && sitem.d.settings"
                                          :defaultSettings="settings.widget"
                                          :readonly="readonly"
                                          :data-key="sitem.i"
                                          @message="handleMessage"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div v-else ref="sitems" :class="'sitem-container ' + (!readonly ? (selectedItem && selectedItem.i == sitem.i ? 'sitem-selected' : '') : '')" :style="widgetStyle(sitem)">
                                  <div :class="selectedItem && selectedItem.i == sitem.i ? 'sitem-drag' : 'sitem-no-drag'" :style="widgetInnerStyle(sitem)">
                                    <div :style="widgetInnerInnerStyle(sitem)">
                                      <div v-if="sitem.d.title" class="item-title" :style="widgetTitleStyle(sitem)">
                                        <div>{{sitem.d.title}}</div>
                                      </div>
                                      <div v-if="sitem.h > 1 || !sitem.d.title || (sitem.d.settings && sitem.d.settings.title && sitem.d.settings.title.hidden)" :data-key="sitem.i" ref="sitemBody" class="item-body">
                                        <dashboard-widget v-if="layouted[sitem.i]" 
                                          ref="scharts" 
                                          :title="sitem.d.title"
                                          :type="sitem.d.type"
                                          :params="params"
                                          :data="modelDatas[sitem.d.model]" 
                                          :value="sitem.d.value"
                                          :settings="sitem.d && sitem.d.settings"
                                          :defaultSettings="settings.widget"
                                          :readonly="readonly"
                                          :data-key="sitem.i"
                                          @message="handleMessage"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </grid-item>
                            </template>
                          </grid-layout>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!--<div v-if="!readonly && !childrenDesign" :class="itemControlClass(item)" @click.stop="handleItemControlsClick(item)">
                    <div class="item-control" title="编辑" @click="handleItemEdit(item)">
                      <i class="el-icon-edit"></i>
                    </div>
                    <div class="item-control" title="删除" @click="handleItemDel(item)">
                      <i class="el-icon-delete"></i>
                    </div>
                    <div v-if="item.d && item.d.type != 'kpi' && item.d.type != 'text' && item.d.type != 'select'" class="item-control" title="子组件布局" @click="handleItemChildrenDesign(item)">
                      <i class="el-icon-menu"></i>
                    </div>
                    <div class="item-control" title="拖动">
                      <i class="el-icon-rank"></i>
                    </div>
                  </div>-->
                </grid-item>
              </template>
            </grid-layout>
            <!-- 开发中 -->
            <!-- 用递归布局代替上面的方式 -->
            <!-- 从左边树形菜单添加子组件，子组件可全屏布局 -->
            <!--<dashbord-widget-layout
              :type="layoutType"
              :settings="settings"
              :defaultChildrenSettings="defaultChildrenSettings"
              :widgets="widgets"
              :params="params"
              :modelDatas="modelDatas"
              :readonly="readonly"
              :childrenDesign="childrenDesign"
              :isRoot="true"
            >
            </dashbord-widget-layout>-->
            <div v-if="selectData" class="select-mask" :style="selectData.maskStyle">
              <div :class="'select-container select-container-' + selectData.theme" :style="selectData.style">
                <div v-for="(item, index) in selectData.data" :key="index" :class="'select-item select-item-' + selectData.theme" @click.stop="handleSelectChange(item)">{{item.label}}</div>
              </div>
            </div>
            <div v-if="openUrlData" class="dialog-mask" :style="openUrlData.maskStyle">
              <div :style="openUrlData.style" @click.stop="">
                <component v-if="openUrlData.component" :is="openUrlData.component" :params="openUrlData.params" @close="openUrlData = null"></component>
                <iframe v-else :src="openUrlData.url" scrolling="yes" frameborder="0" style="width: 100%; height: 100%;"></iframe>
              </div>
            </div>
            <div v-if="popoverUrlData" class="dialog-mask" :style="popoverUrlData.maskStyle">
              <div :style="popoverUrlData.style" @click.stop="">
                <component v-if="popoverUrlData.component" :is="popoverUrlData.component" :params="popoverUrlData.params" @close="popoverUrlData = null"></component>
                <iframe v-else :src="popoverUrlData.url" scrolling="yes" frameborder="0" style="width: 100%; height: 100%;"></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="!readonly && editing" style="z-index: 300; position: absolute; left: 0; top: 0; right: 0; bottom: 0">
      <dashboard-widget-edit :id="editingItem.i" :data="editingItem.d" @cancel="handleEditCancel" @complete="handleEditComplete" />
    </div>
  </div>
</template>

<script>
// 这种import开发环境正常，但是发布环境却出现错误：Cannot assign to read only property 'exports' of object '#<Object>'
// import { GridLayout, GridItem } from "@/plugins/vue-grid-layout"
// 必须把编译好的代码放到node_modules/vue-grid-layout目录再import
import { GridLayout, GridItem } from "vue-grid-layout"
import { getBiDashboard, editBiDashboard } from "@/api/bi/bi_dashboard";
import { getModel, execModel } from "@/api/core/data/datasource/model";
import WidgetLayout from './widget-layout.vue'
import Widget from './widgets/index.vue'
import WidgetEdit from './widget-edit.vue'
import { parseValue } from './utils/common'

const PC_COL_NUM = 36
const PC_ROW_NUM = 18
const PC_CHILDREN_COL_NUM = 10
const PC_CHILDREN_ROW_NUM = 10
const MOBILE_COL_NUM = 2
const MOBILE_ROW_NUM = 12
const MOBILE_CHILDREN_COL_NUM = 10
const MOBILE_CHILDREN_ROW_NUM = 10
const MOBILE_WIDTH = 400
const MOBILE_HEIGHT = 720

export default {
  name: "dashboard",
  components: {
    GridLayout,
    GridItem,
    'dashbord-widget-layout': WidgetLayout,
    'dashboard-widget': Widget,
    'dashboard-widget-edit': WidgetEdit
  },
  props: {
    readonly: {
      type: Boolean,
      default: () => true
    }
  },
  data() {
    const defaultSettings = {
      dashboard: {
        backgroundColor: '#f5f5f5',
        backgroundImage: '',
        backgroundImageScale: 'auto',
        backgroundImageWidth: '',
        backgroundImageHeight: '',
        backgroundImageMaskColor: ''
      },
      widget: {
        backgroundColor: '#fff',
        titleColor: '#000',
        titleFontSize: 18,
        textColor: '#000',
        textFontSize: 12
      },
      grid: {
        cols: PC_COL_NUM,
        rows: PC_ROW_NUM,
        overlap: true,
        vcompact: true,
        margin: 5,
        padding: 10,
        rowHeight: 0
      }
    }
    const defaultChildrenSettings = {
      grid: {
        cols: PC_CHILDREN_COL_NUM,
        rows: PC_CHILDREN_ROW_NUM,
        overlap: true,
        vcompact: false,
        margin: 5,
        padding: 5,
        rowHeight: 0
      }
    }
    return {
      defaultProps: {
        children: "children",
        label: "label",
      },
      activeTab: 'widget',
      defaultSettings: defaultSettings,
      defaultChildrenSettings: defaultChildrenSettings,
      settings: JSON.parse(JSON.stringify(defaultSettings)),
      data: {},
      content: {},
      widgets: [],
      layoutType: 'pc',
      layout: [],
      layouted: {},
      params: {},
      modelInfos: {},
      modelDatas: {},
      mouseOverItem: null,
      selectedItem: null,
      height: 0,
      layoutHeight: 0,
      rowHeight: 40,
      editing: false,
      editingItem: {
        i: "",
        d: {}
      },
      childrenDesign: null,
      change: false,
      cache: {},
      copyWidget: null,
      copyWidgetStyle: null,
      selectData: null,
      openUrlData: null,
      popoverUrlData: null
    };
  },
  created() {
    document.title = this.readonly ? "仪表板预览" : "仪表板设计";
  },
  mounted() {
    window.addEventListener("message", this.onMessage);
    window.addEventListener("resize", this.onWindowResize);
    window.addEventListener("keydown", this.onKeydown);
    this.onWindowResize();

    const id = this.$route.query["_"];
    this.init(id);
    // 复制粘贴初始化
    this.copyTimerId = setInterval(() => {
      const wstr = localStorage.getItem('__dashboard_widget_copy__')
      if (wstr) {
        const json = JSON.parse(wstr)
        if (json.time < new Date().getTime() - 60000) {
          // 过期
          localStorage.removeItem('__dashboard_widget_copy__')
        } else {
          this.copyWidget = json.data
        }
      } else {
        if (this.copyWidget) {
          this.copyWidget = null
        }
      }
      const sstr = localStorage.getItem('__dashboard_widget_style_copy__')
      if (sstr) {
        const json = JSON.parse(sstr)
        if (json.time < new Date().getTime() - 600000) {
          // 过期
          localStorage.removeItem('__dashboard_widget_style_copy__')
        } else {
          this.copyWidgetStyle = json.data
        }
      } else {
        if (this.copyWidgetStyle) {
          this.copyWidgetStyle = null
        }
      }
    }, 1000)
  },
  destroyed() {
    window.removeEventListener("message", this.onMessage);
    window.removeEventListener("resize", this.onWindowResize);
    window.removeEventListener("keydown", this.onKeydown);
    if (this.refreshDataTimerId) {
      clearTimeout(this.refreshDataTimerId)
    }
    if (this.copyTimerId) {
      clearInterval(this.copyTimerId)
    }
  },
  methods: {
    onMessage(e) {
      if (typeof e.data == 'object') {
        if (e.data.type == 'bi_param_set') {
          console.log(e.data)
          const params = {
            ...this.params
          }
          if (e.data.value === undefined) {
            delete params[e.data.key]
          }
          params[e.data.key] = e.data.value
          this.params = params
          if (this.delayLoadDataTimerId) {
            clearTimeout(this.delayLoadDataTimerId)
          }
          this.delayLoadDataTimerId = setTimeout(() => {
            let change = false
            let changeParams = {}
            if (this.lastLoadDataParams) {
              for (let key in this.params) {
                if (this.params[key] != this.lastLoadDataParams[key]) {
                  changeParams[key] = this.params[key]
                  change = true
                }
              }
              for (let key in this.lastLoadDataParams) {
                if (this.params[key] != this.lastLoadDataParams[key]) {
                  changeParams[key] = this.params[key]
                  change = true
                }
              }
            } else {
              change = true
              changeParams = undefined
            }

            this.lastLoadDataParams = JSON.parse(JSON.stringify(this.params))
            this.delayLoadDataTimerId = undefined
            if (change) {
              this.loadModelDataIfNeeded({}, changeParams)
            }
          }, 100)
        } else if (e.data.type == 'bi_dialog_close') {
          this.openUrlData = null
        }
      }
    },
    onWindowResize() {
      let mobile = false
      const ua = navigator.userAgent.toLowerCase();
      if (/ipad|iphone|midp|rv:1.2.3.4|ucweb|android|windows ce|windows mobile/.test(ua)) {
        mobile = true
      }
      this.height = document.documentElement.clientHeight
      if (this.readonly) {
        this.layoutHeight = document.documentElement.clientHeight
        if (mobile) {
          if (!this.realLayoutType || this.realLayoutType != 'mobile') {
            this.realLayoutType = 'mobile'
          }
        } else {
          if (!this.realLayoutType || this.realLayoutType != this.layoutType) {
            this.realLayoutType = this.layoutType
          }
        }
        this.handleLayoutChanged(this.realLayoutType)
      } else {
        if (this.layoutType == 'mobile') {
          this.layoutHeight = MOBILE_HEIGHT
        } else {
          this.layoutHeight = this.height - 40
        }
        this.handleLayoutChanged(this.layoutType)
      }
    },

    onKeydown(e) {
      //console.log(e)
      if (this.editing) {
        return
      }
      if (e.keyCode == 13) {
        // enter
        if (this.selectedItem) {
          this.handleItemEdit(this.selectedItem)
        }
      } else if (e.keyCode == 46) {
        // delete
        if (this.selectedItem) {
          this.handleItemDel(this.selectedItem)
        }
      } else if (e.keyCode == 67 && e.ctrlKey) {
        // ctrl + c
        if (this.selectedItem) {
          this.handleItemCopy(this.selectedItem)
        }
      } else if (e.keyCode == 86 && e.ctrlKey) {
        // ctrl + v
        if (this.copyWidget) {
          this.handleItemPasteConfirm(this.selectedItem)
        }
      } else if (e.keyCode == 67 && e.altKey) {
        // alt + c
        if (this.selectedItem) {
          this.handleItemCopy(this.selectedItem, true)
        }
      } else if (e.keyCode == 86 && e.altKey) {
        // alt + v
        if (this.copyWidgetStyle) {
          this.handleItemStylePasteConfirm()
        }
      }
    },

    init(id) {
      const loading = this.$loading({
        lock: true,
        text: '正在加载数据',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.1)'
      });
      getBiDashboard(id).then((response) => {
        loading.close()
        if (response.code == 0) {
          this.params = {}
          this.layouted = {}

          this.data = response.data;
          document.title = (this.readonly ? "" : "仪表板设计 - ") + this.data.name
          if (this.data.content) {
            const content = JSON.parse(this.data.content)
            this.content = content
            //console.log(content)
            this.widgets = content.widgets
            this.initParams()
            this.realLayoutType = 'pc'
            this.handleLayoutChanged('pc')
            this.handleSettingTextChange()
            this.lastLoadDataParams = JSON.parse(JSON.stringify(this.params))
            this.loadModelDataIfNeeded({})
            this.onWindowResize()
          }
        }
      }).catch(e => {
        loading.close()
      })
    },

    initParams() {
      const params = {}
      for (let i = 0; i < this.widgets.length; i++) {
        const widget = this.widgets[i]
        if (widget.data.type == 'select' && widget.data.value && widget.data.settings.event && widget.data.settings.event.prefix) {
          params[widget.data.settings.event.prefix] = parseValue(widget.data.value, undefined, this.$route && this.$route.query)
        }
        if (widget.widgets) {
          for (let j = 0; j < widget.widgets.length; j++) {
            const swidget = widget.widgets[j]
            if (swidget.data.type == 'select' && swidget.data.value && swidget.data.settings.event && swidget.data.settings.event.prefix) {
              params[swidget.data.settings.event.prefix] = parseValue(swidget.data.value, this.$route && this.$route.query)
            }
          }
        }
      }
      this.params = params
    },

    isChangeParams(d, changeParams) {
      let change = false
      const modelInfo = d && d.model ? this.modelInfos[d.model] : undefined
      if (changeParams && modelInfo && modelInfo.params) {
        for (let i = 0; i < modelInfo.params.length; i++) {
          const p = modelInfo.params[i]
          for (let key in changeParams) {
            if (p.name == key) {
              change = true
              break
            }
          }
          if (change) {
            break
          }
        }
      } else {
        change = true
      }
      return change
    },

    makesureLoadModelInfo(modelId) {
      return new Promise((resolve, reject) => {
        const modelInfo = this.modelInfos[modelId]
        if (modelInfo) {
          resolve(modelInfo)
        } else {
          getModel(modelId).then(response => {
            this.modelInfos[modelId] = response.data
            resolve(response.data)
          }).catch(e => {
            reject(e)
          })
        }
      })
    },

    loadModelDataIfNeeded(force, changeParams) {
      let d = undefined
      for (let i = 0; i < this.layout.length; i++) {
        const l = this.layout[i]
        if (l.d && l.d.model && this.isChangeParams(l.d, changeParams) && (!this.modelDatas[l.d.model] || (force && !force[l.d.model]))) {
          d = l.d
          break
        }
      }

      if (!d) {
        for (let i = 0; i < this.layout.length; i++) {
          const l = this.layout[i]
          if (l.children) {
            for (let j = 0; j < l.children.length; j++) {
              const sl = l.children[j]
              if (sl.d && sl.d.model && this.isChangeParams(sl.d, changeParams) && (!this.modelDatas[sl.d.model] || (force && !force[sl.d.model]))) {
                d = sl.d
                break
              }
            }
          }
          if (d) {
            break
          }
        }
      }

      if (d && d.model && (!this.modelDatas[d.model] || (force && !force[d.model]))) {
        this.makesureLoadModelInfo(d.model).then(modelInfo => {
          const maxCount = 1000
          const params = {}
          if (modelInfo && modelInfo.params && modelInfo.params.length > 0) {
            if (this.$route.query) {
              for (let key in this.$route.query) {
                if (key != "_") {
                  for (let i = 0; i < modelInfo.params.length; i++) {
                    const p = modelInfo.params[i]
                    if (p.name == key) {
                      params[key] = this.$route.query[key]
                      break
                    }
                  }
                }
              }
            }
            if (this.params) {
              for (let key in this.params) {
                if (key != "_") {
                  for (let i = 0; i < modelInfo.params.length; i++) {
                    const p = modelInfo.params[i]
                    if (p.name == key) {
                      params[key] = this.params[key]
                      break
                    }
                  }
                }
              }
            }
          }
          params['__max_count__'] = maxCount
          execModel(d.model, JSON.stringify(params)).then(response => {
            let data = undefined
            if (!response || typeof response != 'object' || !response.data || typeof response != 'object') {
              data = [] // 防止一直循环尝试
              console.log('Invalid response data: ', response)
            } else {
              data = response.data
            }
            const modelDatas = {...this.modelDatas}
            modelDatas[d.model] = data
            this.modelDatas = modelDatas
            if (force) {
              force[d.model] = d.model
            }

            this.loadModelDataIfNeeded(force, changeParams)
          }).catch(e => {
            console.log(e)
          })
        })
      } else {
        this.setRefreshDataTimer()
      }
    },

    getSettings(type) {
      if (!this.content.settings) {
        this.content.settings = {}
      }
      let settings = this.content.settings[type]
      if (!settings) {
        settings = JSON.parse(JSON.stringify(this.defaultSettings))
        settings.grid.cols = type == 'mobile' ? MOBILE_COL_NUM : PC_COL_NUM
        settings.grid.rows = type == 'mobile' ? MOBILE_ROW_NUM : PC_ROW_NUM
        this.content.settings[type] = settings
      }
      return settings
    },

    handleLayoutChanged(type) {
      const settings = this.getSettings(type)

      const layout = []
      for (let i = 0; i < this.widgets.length; i++) {
        const widget = this.widgets[i]
        const l = type == 'mobile' ? widget.layout.mobile : widget.layout.pc
        let childrenSettings = undefined
        if (widget.settings) {
          childrenSettings = widget.settings[type]
          if (!childrenSettings) {
            childrenSettings = JSON.parse(JSON.stringify(this.defaultChildrenSettings))
            childrenSettings.grid.cols = type == 'mobile' ? MOBILE_CHILDREN_COL_NUM : PC_CHILDREN_COL_NUM
            childrenSettings.grid.rows = type == 'mobile' ? MOBILE_CHILDREN_ROW_NUM : PC_CHILDREN_ROW_NUM
          }
        }

        let children = undefined
        if (widget.widgets && widget.widgets.length > 0) {
          children = []
          for (let j = 0; j < widget.widgets.length; j++) {
            const swidget = widget.widgets[j]
            const sl = type == 'mobile' ? swidget.layout.mobile : swidget.layout.pc
            children.push({
              ...sl,
              i: swidget.layout.i,
              d: swidget.data,
              pi: widget.layout.i
            })
          }
        }

        layout.push({
          ...l,
          i: widget.layout.i,
          d: widget.data,
          settings: childrenSettings,
          children: children
        })
      }
      this.layout = layout
      console.log(layout)

      this.colNum = settings.grid.cols
      this.rowNum = settings.grid.rows
      this.rowHeight = (this.layoutHeight - ((this.rowNum + 1) * this.settings.grid.margin)) / this.rowNum

      this.settings = settings

      this.setRefreshDataTimer()
    },

    handleLayoutTypeChange() {
      this.onWindowResize()
      this.realLayoutType = this.layoutType
      this.handleLayoutChanged(this.layoutType)
      
      this.$nextTick(() => {
        setTimeout(() => {
          this.chartsResize()
        }, 100)
      })
    },

    getExtend(textColor, fontSize) {
      const tc = textColor || '#000'
      const fs = fontSize || 12
      return {
        //textStyle: {color: '#f00'},
        xAxis: {
          axisLabel: {
            color: tc,
            fontSize: fs
          }
        },
        yAxis: {
          axisLabel: {
            color: tc,
            fontSize: fs
          }
        },
        legend: {
          textStyle: {
            color: tc,
            fontSize: fs
          }
        }
      }
    },

    handleSettingTextChange() {
    },

    handleSettingChange() {
      this.change = true
      this.settings.widget = {...this.settings.widget}
    },

    handleSettingTextColorChange() {
      this.handleSettingChange()
      this.handleSettingTextChange()
    },

    handleSettingTextSizeChange() {
      this.handleSettingChange()
      this.handleSettingTextChange()
      this.handleSettingSizeChange()
    },

    handleRefreshDataDurationChange() {
      this.change = true
      this.setRefreshDataTimer()
    },

    setRefreshDataTimer() {
      if (this.refreshDataTimerId) {
        clearTimeout(this.refreshDataTimerId)
      }
      // 定时刷新数据
      if (this.settings.refreshDataDuration) {
        this.refreshDataTimerId = setTimeout(() => {
          this.loadModelDataIfNeeded({})
        }, 1000 * this.settings.refreshDataDuration)
      }
    },

    chartsResize() {
      const charts = this.$refs.charts
      if (charts) {
        for (let i = 0; i < charts.length; i++) {
          charts[i].resize()
        }
      }
      const scharts = this.$refs.scharts
      if (scharts) {
        for (let i = 0; i < scharts.length; i++) {
          scharts[i].resize()
        }
      }
    },

    handleSettingGridChange() {
      this.handleLayoutChanged(this.layoutType)
      this.handleSettingSizeChange()
    },

    handleChildrenSettingGridChange() {
      this.handleSettingGridChange()
    },

    handleSettingSizeChange() {
      this.handleSettingChange()
      this.onWindowResize()

      this.$nextTick(() => {
        setTimeout(() => {
          this.chartsResize()
        }, 100)
      })
    },

    handleChildrenSettingSizeChange() {
      this.handleSettingSizeChange()
    },

    handleLayoutUpdated(layout) {
      this.$nextTick(() => {
        const layouted = {
          ...this.layouted
        }
        for (let i = 0; i < layout.length; i++) {
          const l = layout[i]
          layouted[l.i] = true
          if (!this.readonly) {
            for (let j = 0; j < this.widgets.length; j++) {
              const widget = this.widgets[j]
              if (widget.layout.i == l.i) {
                widget.layout[this.layoutType] = {
                  x: l.x,
                  y: l.y,
                  w: l.w,
                  h: l.h
                }
              }
            }
          }
        }

        this.layouted = layouted

        this.$nextTick(() => {
          const charts = this.$refs.charts
          if (charts) {
            for (let i = 0; i < charts.length; i++) {
              charts[i].resize()
            }
          }
          
          this.updateChildrenLayoutRowHeight()
        })
      })
    },

    handleSLayoutUpdated(layout) {
      this.$nextTick(() => {
        setTimeout(() => {
          const layouted = {
            ...this.layouted
          }
          for (let i = 0; i < layout.length; i++) {
            const l = layout[i]
            layouted[l.i] = true
            if (!this.readonly) {
              for (let j = 0; j < this.widgets.length; j++) {
                const widget = this.widgets[j]
                if (widget.widgets) {
                  for (let k = 0; k < widget.widgets.length; k++) {
                    const swidget = widget.widgets[k]
                    if (swidget.layout.i == l.i) {
                      swidget.layout[this.layoutType] = {
                        x: l.x,
                        y: l.y,
                        w: l.w,
                        h: l.h
                      }
                    }
                  }
                }
              }
            }
          }

          this.layouted = layouted

          this.$nextTick(() => {
            const scharts = this.$refs.scharts
            if (scharts) {
              for (let i = 0; i < scharts.length; i++) {
                scharts[i].resize()
              }
            }
          })
        }, 200)
      })
    },

    updateChildrenLayoutRowHeight() {
      if (this.$refs.itemBody) {
        for (let i = 0; i < this.$refs.itemBody.length; i++) {
          const itemBody = this.$refs.itemBody[i]
          const key = itemBody.getAttribute('data-key')
          const height = itemBody.offsetHeight
          for (let i = 0; i < this.layout.length; i++) {
            const l = this.layout[i]
            if (l.i == key && l.settings) {
              l.settings.grid.rowHeight = (height - (l.settings.grid.rows + 1) * l.settings.grid.margin) / l.settings.grid.rows
              break
            }
          }
        }
      }
    },

    handleMouseOverTreeItem(data) {
      this.mouseOverItem = data
    },

    handleMouseLeaveTreeItem(data) {
      this.mouseOverItem = null
    },

    handleClickTreeItem(data) {
      this.selectedItem = data
    },

    handleDblClickTreeItem(data) {
      this.handleItemEdit(data)
    },

    handleContextMenuTreeItem(data) {
      // @contextmenu.prevent
      this.setSelectedItem(data)
    },

    handleDropdownVisibleChange(e, data) {
      this.setSelectedItem(data)
    },

    expandParent(item) {
      for (let i = 0; i < this.layout.length; i++) {
        const l = this.layout[i]
        if (l.children) {
          for (let j = 0; j < l.children.length; j++) {
            if (l.children[j].i == item.i) {
              this.$refs.tree.store.nodesMap[l.i].expanded = true
              return
            }
          }
        }
      }
    },

    setSelectedItem(item) {
      this.selectedItem = item
      this.$nextTick(() => {
        if (this.$refs.tree) {
          this.expandParent(item)
          this.$refs.tree.setCurrentNode(item)
        }
      })
    },

    handleCommand(cmd) {
      if (cmd.cmd === 'edit') {
        this.handleItemEdit(cmd.item)
      } else if (cmd.cmd === 'delete') {
        this.handleItemDel(cmd.item)
      } else if (cmd.cmd === 'copy') {
        this.handleItemCopy(cmd.item)
      } else if (cmd.cmd === 'paste') {
        this.handleItemPasteConfirm(cmd.item)
      } else if (cmd.cmd == 'childrenDesign') {
        this.handleItemChildrenDesign(cmd.item)
      } else if (cmd.cmd == 'top') {
        this.handleItemTop(cmd.item)
      } else if (cmd.cmd == 'bottom') {
        this.handleItemBottom(cmd.item)
      }
    },

    makeCommand(cmd, item) {
      return {
        cmd: cmd,
        item: item
      }
    },

    handleClick() {
      this.selectedItem = null
      this.selectData = null
      this.openUrlData = null
      this.popoverUrlData = null
    },

    handleItemClick(item) {
      if (this.readonly || this.childrenDesign) {
        return
      }
      this.setSelectedItem(item)
    },

    handleItemDblClick(item) {
      this.handleItemEdit(item)
    },

    handleSItemClick(item) {
      if (this.readonly) {
        return
      }
      this.setSelectedItem(item)
    },

     handleSItemDblClick(item) {
      this.handleItemEdit(item)
    },

    handleItemControlsClick(item) {
    },

    handleItemEdit(item) {
      //console.log(item)
      this.editingItem = item
      this.editing = true
    },

    handleItemCopy(item, copyStyle) {
      let copyWidget = null
      for (let i = 0; i < this.widgets.length; i++) {
        const widget = this.widgets[i]
        if (widget.layout.i == item.i) {
          copyWidget = widget
          break
        }
        if (widget.widgets) {
          for (let j = 0; j < widget.widgets.length; j++) {
            const swidget = widget.widgets[j]
            if (swidget.layout.i == item.i) {
              copyWidget = swidget
              break
            }
          }
        }
      }
      if (copyWidget) {
        if (copyStyle) {
          if (copyWidget.data.type == 'text' || copyWidget.data.type == 'kpi' || copyWidget.data.type == 'select') {
            this.copyWidgetStyle = copyWidget
            localStorage.setItem('__dashboard_widget_style_copy__', JSON.stringify({
              time: new Date().getTime(),
              data: copyWidget
            }))

            this.$message({
              message: '已复制样式：' + (copyWidget.data.title || '未命名组件'),
              type: 'success'
            });
          }
        } else {
          this.copyWidget = copyWidget
          localStorage.setItem('__dashboard_widget_copy__', JSON.stringify({
            time: new Date().getTime(),
            data: copyWidget
          }))

          this.$message({
            message: '已复制组件：' + (copyWidget.data.title || '未命名组件'),
            type: 'success'
          });
        }
      }
    },

    handleItemPasteConfirm(item) {
      if (!this.copyWidget) {
        return
      }
      const copyWidget = this.copyWidget
      this.$confirm('确定粘贴"' + (copyWidget.data.title || '未命名组件') + '"吗?', "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        //type: "warning",
      })
      .then(() => {
        //this.copyWidget = null
        //localStorage.removeItem('__dashboard_widget_copy__')
        this.handleItemPaste(item, copyWidget)
      }).catch((e) => console.log(e))
    },

    copyProp(dst, src) {
      if (!src) {
        return dst
      }
      if (!dst) {
        dst = {}
      }
      for (let key in src) {
        const p = src[key]
        dst[key] = p
      }
      return dst
    },

    handleItemStylePasteConfirm() {
      if (!this.copyWidgetStyle || !this.selectedItem) {
        return
      }
      if (!((this.copyWidgetStyle.data.type == 'text' || this.copyWidgetStyle.data.type == 'kpi' || this.copyWidgetStyle.data.type == 'select') &&
        (this.selectedItem.d.type == 'text' || this.selectedItem.d.type == 'kpi' || this.selectedItem.d.type == 'select'))) {
        return
      }
      const copyWidgetStyle = this.copyWidgetStyle
      this.$confirm('确定粘贴样式"' + (copyWidgetStyle.data.title || '未命名组件') + '"到"' + (this.selectedItem.d.title || '未命名组件') + '"吗?', "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        //type: "warning",
      })
      .then(() => {
        //this.copyWidgetStyle = null
        //localStorage.removeItem('__dashboard_widget_style_copy__')
        //console.log(copyWidgetStyle, this.selectedItem)
        const oldSettings = JSON.parse(JSON.stringify(this.selectedItem.d.settings))
        const newSettings = copyWidgetStyle.data.settings
        oldSettings.background = this.copyProp(oldSettings.background, JSON.parse(JSON.stringify(newSettings.background)))
        oldSettings.border = this.copyProp(oldSettings.border, JSON.parse(JSON.stringify(newSettings.border)))
        oldSettings.padding = this.copyProp(oldSettings.padding, JSON.parse(JSON.stringify(newSettings.padding)))
        const title = oldSettings.title && oldSettings.title.title || ''
        oldSettings.title = this.copyProp(oldSettings.title, JSON.parse(JSON.stringify(newSettings.title)))
        if (title) {
          oldSettings.title.title = title
        }
        oldSettings.text = this.copyProp(oldSettings.text, JSON.parse(JSON.stringify(newSettings.text)))
        if (newSettings.prefix) {
          oldSettings.prefix = oldSettings.prefix || {}
          oldSettings.prefix.textColor = newSettings.prefix.textColor
          oldSettings.prefix.fontSize = newSettings.prefix.fontSize
          oldSettings.prefix.bold = newSettings.prefix.bold
          oldSettings.prefix.iconColor = newSettings.prefix.iconColor
          oldSettings.prefix.iconSize = newSettings.prefix.iconSize
        }
        if (newSettings.suffix) {
          oldSettings.suffix = oldSettings.suffix || {}
          oldSettings.suffix.textColor = newSettings.suffix.textColor
          oldSettings.suffix.fontSize = newSettings.suffix.fontSize
          oldSettings.suffix.bold = newSettings.suffix.bold
          oldSettings.suffix.iconColor = newSettings.suffix.iconColor
          oldSettings.suffix.iconSize = newSettings.suffix.iconSize
        }
        if (newSettings.unit) {
          oldSettings.unit = oldSettings.unit || {}
          oldSettings.unit.textColor = newSettings.unit.textColor
          oldSettings.unit.fontSize = newSettings.unit.fontSize
          oldSettings.unit.bold = newSettings.unit.bold
        }
        this.selectedItem.d.settings = oldSettings
        for (let i = 0; i < this.widgets.length; i++) {
          const widget = this.widgets[i]
          if (widget.layout.i == this.selectedItem.i) {
            widget.data.settings = oldSettings
            break
          }
          if (widget.widgets) {
            for (let j = 0; j < widget.widgets.length; j++) {
              const swidget = widget.widgets[j]
              if (swidget.layout.i == this.selectedItem.i) {
                swidget.data.settings = oldSettings
                break
              }
            }
          }
        }
        this.change = true
      }).catch((e) => console.log(e))
    },
    
    handleItemPaste(pasteAfterItem, copyFromItem) {
      if (!copyFromItem) {
        return
      }
      let widgets = this.widgets
      let index = -1
      let maxI = 0
      for (let i = 0; i < this.widgets.length; i++) {
        const widget = this.widgets[i]
        let idx = parseInt(widget.layout.i)
        if (maxI < idx) {
          maxI = idx
        }
        if (index == -1 && pasteAfterItem && widget.layout.i == pasteAfterItem.i) {
          index = i
        }
        if (widget.widgets) {
          for (let j = 0; j < widget.widgets.length; j++) {
            const swidget = widget.widgets[j]
            let idx = parseInt(swidget.layout.i)
            if (maxI < idx) {
              maxI = idx
            }
            if (index == -1 && pasteAfterItem && swidget.layout.i == pasteAfterItem.i) {
              widgets = widget.widgets
              index = j
            }
          }
        }
      }

      if (index < 0) {
        index = this.widgets.length - 1
      }

      let copyItem = JSON.parse(JSON.stringify(copyFromItem))
      copyItem.layout.i = ++maxI
      if (copyItem.widgets) {
        for (let i = 0; i < copyItem.widgets.length; i++) {
          copyItem.widgets[i].layout.i = ++maxI
        }
      }
      widgets.splice(index + 1, 0, copyItem)
      this.change = true
      this.handleLayoutTypeChange()
      this.loadModelDataIfNeeded()

      for (let i = 0; i < this.layout.length; i++) {
        const l = this.layout[i]
        if (l.i == copyItem.layout.i) {
          this.setSelectedItem(l)
          break
        }
        if (l.children) {
          for (let j = 0; j < l.children.length; j++) {
            const sl = l.children[j]
            if (sl.i == copyItem.layout.i) {
              this.setSelectedItem(sl);
              break;
            }
          }
        }
      }

      this.$message({
        message: '已粘贴组件：' + (copyFromItem.data.title || '未命名组件'),
        type: 'success'
      });
    },

    handleItemDel(item) {
      this.$confirm('确定删除"' + (item.d.title || '未命名组件') + '"吗?', "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
      .then(() => {
        let del = false
        for (let i = 0; i < this.layout.length; i++) {
          const l = this.layout[i]
          if (l.i == item.i) {
            this.layout.splice(i, 1)
            del = true
          }
          if (!del && l.children) {
            for (let j = 0; j < l.children.length; j++) {
              const sl = l.children[j]
              if (sl.i == item.i) {
                l.children.splice(j, 1)
                del = true
                break
              }
            }
          }
          if (del) {
            break
          }
        }

        del = false
        for (let i = 0; i < this.widgets.length; i++) {
          const widget = this.widgets[i]
          if (widget.layout.i == item.i) {
            this.widgets.splice(i, 1)
            del = true
          }
          if (!del && widget.widgets) {
            for (let j = 0; j < widget.widgets.length; j++) {
              const swidget = widget.widgets[j]
              if (swidget.layout.i == item.i) {
                widget.widgets.splice(j, 1)
                del = true
                break
              }
            }
          }
          if (del) {
            break
          }
        }
        if (item == this.selectedItem) {
          this.selectedItem = null
        }
        this.change = true
      }).catch(() => {})
    },

    handleItemTop(item) {
      let widgets = this.widgets
      let index = -1
      for (let i = 0; i < this.widgets.length; i++) {
        const widget = this.widgets[i]
        if (widget.layout.i == item.i) {
          index = i
          break
        }
        if (widget.widgets) {
          for (let j = 0; j < widget.widgets.length; j++) {
            const swidget = widget.widgets[j]
            if (swidget.layout.i == item.i) {
              widgets = widget.widgets
              index = j
              break
            }
          }
        }
      }
      if (index >= 0) {
        const widget = widgets[index]
        widgets.splice(index, 1)
        widgets.splice(0, 0, widget)
        this.change = true
        this.handleLayoutTypeChange()
      }
    },

    handleItemBottom(item) {
      let widgets = this.widgets
      let index = -1
      for (let i = 0; i < this.widgets.length; i++) {
        const widget = this.widgets[i]
        if (widget.layout.i == item.i) {
          index = i
          break
        }
        if (widget.widgets) {
          for (let j = 0; j < widget.widgets.length; j++) {
            const swidget = widget.widgets[j]
            if (swidget.layout.i == item.i) {
              widgets = widget.widgets
              index = j
              break
            }
          }
        }
      }
      if (index >= 0) {
        const widget = widgets[index]
        widgets.splice(index, 1)
        widgets.push(widget)
        this.change = true
        this.handleLayoutTypeChange()
      }
    },

    handleItemChildrenDesign(item) {
      this.childrenDesign = item;

      for (let i = 0; i < this.widgets.length; i++) {
        const widget = this.widgets[i]
        if (widget.layout.i == this.childrenDesign.i) {
          if (!this.childrenDesign.settings) {
            if (!widget.settings) {
              widget.settings = {
                pc: JSON.parse(JSON.stringify(this.defaultChildrenSettings)),
                mobile: JSON.parse(JSON.stringify(this.defaultChildrenSettings))
              }
              widget.settings.pc.grid.cols = PC_CHILDREN_COL_NUM
              widget.settings.pc.grid.rows = PC_CHILDREN_ROW_NUM
              widget.settings.mobile.grid.cols = MOBILE_CHILDREN_COL_NUM
              widget.settings.mobile.grid.rows = MOBILE_CHILDREN_ROW_NUM
            }
            this.childrenDesign.settings = widget.settings[this.layoutType]
          }

          if (!this.childrenDesign.children) {
            if (!widget.widgets) {
              widget.widgets = []
            }
            this.childrenDesign.children = []
          }
          break
        }
      }

      this.updateChildrenLayoutRowHeight()
    },

    handleItemResize(e) {
      this.$nextTick(() => {
        const charts = this.$refs.charts
        if (charts) {
          for (let i = 0; i < charts.length; i++) {
            if(charts[i].$el.getAttribute('data-key') == e) {
              charts[i].resize();
              break;
            }
          }
        }
      })
    },

    handleItemResized(e) {
      this.handleItemResize(e)

      this.change = true

      let item = null
      for (let i = 0; i < this.layout.length; i++) {
        const l = this.layout[i]
        if (l.i == e) {
          item = l
          break
        }
      }
      if (item) {
        setTimeout(() => {
          this.setSelectedItem(item)
        }, 1)
      }
    },

    handleItemMove(e) {
    },

    handleItemMoved(e) {
      this.handleItemMove(e)

      this.change = true

      let item = null
      for (let i = 0; i < this.layout.length; i++) {
        const l = this.layout[i]
        if (l.i == e) {
          item = l
          break
        }
      }
      if (item) {
        setTimeout(() => {
          this.setSelectedItem(item)
        }, 1)
      }
    },

    handleSItemResize(e) {
      this.$nextTick(() => {
        const scharts = this.$refs.scharts
        if (scharts) {
          for (let i = 0; i < scharts.length; i++) {
            if(scharts[i].$el.getAttribute('data-key') == e) {
              scharts[i].resize();
              break;
            }
          }
        }
      })
    },

    handleSItemResized(e) {
      this.handleSItemResize(e)

      this.change = true

      let item = null
      for (let i = 0; i < this.layout.length; i++) {
        const l = this.layout[i]
        if (l.children) {
          for (let j = 0; j < l.children.length; j++) {
            const sl = l.children[j]
            if (sl.i == e) {
              item = sl
              break
            }
          }
        }
        if (item) {
          break
        }
      }
      if (item) {
        setTimeout(() => {
          this.setSelectedItem(item)
        }, 1)
      }
    },

    handleSItemMove(e) {
    },

    handleSItemMoved(e) {
      this.handleSItemMove(e)

      this.change = true

      let item = null
      for (let i = 0; i < this.layout.length; i++) {
        const l = this.layout[i]
        if (l.children) {
          for (let j = 0; j < l.children.length; j++) {
            const sl = l.children[j]
            if (sl.i == e) {
              item = sl
              break
            }
          }
        }
        if (item) {
          break
        }
      }
      if (item) {
        setTimeout(() => {
          this.setSelectedItem(item)
        }, 1)
      }
    },

    loadItemData(item) {
      if (!item.d) {
        return
      }
      if (!this.modelDatas[item.d.model]) {
        this.loadModelDataIfNeeded()
      }
    },

    is_rect_intersect(x01, x02, y01, y02, x11, x12, y11, y12) {
      const zx = Math.abs(x01 + x02 -x11 - x12);
      const x  = Math.abs(x01 - x02) + Math.abs(x11 - x12);
      const zy = Math.abs(y01 + y02 - y11 - y12);
      const y  = Math.abs(y01 - y02) + Math.abs(y11 - y12);
      if (zx < x && zy < y)
        return true;
      else
        return false;
    },

    getNewLayout(layoutType, dw, dh) {
      let widgets = undefined
      let settings = undefined
      if (this.childrenDesign) {
        for (let i = 0; i < this.widgets.length; i++) {
          const widget = this.widgets[i]
          if (widget.layout.i == this.childrenDesign.i) {
            if (widget.settings) {
              settings = widget.settings[layoutType]
            }
            if (widget.widgets) {
              widgets = widget.widgets
            }
            break
          }
        }
      } else {
        widgets = this.widgets
        settings = this.getSettings(layoutType)
      }

      let maxY = 0
      for (let i = 0; i < widgets.length; i++) {
        const l = layoutType == 'pc' ? widgets[i].layout.pc : widgets[i].layout.mobile
        if (maxY < l.y + l.h) {
          maxY = l.y + l.h
        }
      }

      let w = dw || 0
      let h = dh || 0
      if (this.childrenDesign) {
        if (layoutType == 'mobile') {
          w = w || parseInt(settings.grid.cols / 2)
          h = h || parseInt(settings.grid.rows / 2)
        } else {
          w = w || parseInt(settings.grid.cols / 2)
          h = h || parseInt(settings.grid.rows / 2)
        }
      } else {
        if (layoutType == 'mobile') {
          w = w || settings.grid.cols
          h = h || parseInt(settings.grid.rows / 2)
        } else {
          w = w || parseInt(settings.grid.cols / 4)
          h = h || parseInt(settings.grid.rows / 2)
        }
      }

      if (w <= 0) {
        w = 1
      }

      if (h <= 0) {
        h = 1
      }

      const colNum = settings.grid.cols
      let rs = null
      for (let y = 0; y <= maxY; y++) {
        for (let x = 0;  x <= colNum - w; x++) {
          if (this.childrenDesign && (y >= settings.grid.rows || x >= settings.grid.cols)) {
            continue
          }
          const r = {
            x: x,
            y: y,
            w: w,
            h: h
          }
          let intersect = false
          for (let i = 0; i < widgets.length; i++) {
            const l = layoutType == 'pc' ? widgets[i].layout.pc : widgets[i].layout.mobile
            if (this.is_rect_intersect(r.x, r.x + r.w, r.y, r.y + r.h, l.x, l.x + l.w, l.y, l.y + l.h)) {
              intersect = true
              break
            }
          }
          if (!intersect) {
            rs = r
            break
          }
        }
        if (rs) {
          break
        }
      }

      if (!rs) {
        rs = {
          x: 0,
          y: 0,
          w: w,
          h: h
        }
      }

      return rs
    },

    addItem(d) {
      let maxI = 0
      for (let i = 0; i < this.layout.length; i++) {
        const l = this.layout[i]
        let idx = parseInt(l.i)
        if (maxI < idx) {
          maxI = idx
        }
        if (l.children) {
          for (let j = 0; j < l.children.length; j++) {
            const sl = l.children[j]
            let idx = parseInt(sl.i)
            if (maxI < idx) {
              maxI = idx
            }
          }
        }
      }

      const pcLayout = this.getNewLayout('pc')
      const mobileLayout = this.getNewLayout('mobile')
      
      if (pcLayout && mobileLayout) {
        let widgets = undefined
        if (this.childrenDesign) {
          for (let i = 0; i < this.widgets.length; i++) {
            const widget = this.widgets[i]
            if (widget.layout.i == this.childrenDesign.i) {
              if (widget.widgets) {
                widgets = widget.widgets
              }
              break
            }
          }
        } else {
          widgets = this.widgets
        }
        const i = maxI + 1 + ''
        widgets.push({
          data: d,
          layout: {
            i: i,
            pc: {
              ...pcLayout
            },
            mobile: {
              ...mobileLayout
            }
          }
        })
        const rs = this.layoutType == 'pc' ? pcLayout : mobileLayout
        rs.i = i
        rs.d = d
        if (this.childrenDesign) {
          rs.pi = this.childrenDesign.i
          this.childrenDesign.children.push(rs)
          this.setSelectedItem(rs)
        } else {
          this.layout.push(rs)
          this.setSelectedItem(rs)
        }
        this.loadItemData(rs)
        return true
      } else {
        return false
      }
    },

    handleAdd() {
      this.editingItem = {
        i: "",
        d: {}
      }
      this.editing = true
    },

    handleEditCancel() {
      this.editing = false
    },

    handleEditComplete(i, d) {
      this.editing = false
      if (i) {
        let item = undefined
        for (let j = 0; j < this.layout.length; j++) {
          const l = this.layout[j]
          if (l.i == i) {
            item = l
            break
          }
          if (l.children) {
            for (let k = 0; k < l.children.length; k++) {
              const sl = l.children[k]
              if (sl.i == i) {
                item = sl
                break
              }
            }
          }
        }
        if (item) {
          item.d = d
          for (let j = 0; j < this.widgets.length; j++) {
            const widget = this.widgets[j]
            if (widget.layout.i == i) {
              widget.data = d
              break
            }
            if (widget.widgets) {
              for (let k = 0; k < widget.widgets.length; k++) {
                const swidget = widget.widgets[k]
                if (swidget.layout.i == i) {
                  swidget.data = d
                  break
                }
              }
            }
          }
          this.loadItemData(item)
          //this.change = true
          this.handleSave()
        }
      } else {
        if (this.addItem(d)) {
          //this.change = true
          this.handleSave()
        }
      }
      this.$nextTick(() => {
        this.chartsResize()
      })
    },

    handlePreview() {
      window.open("bi.html#/dashboard/view?_=" + this.$route.query["_"]);
    },

    handleSave() {
      if (this.readonly) {
        console.log('readonly')
        return
      }

      const layout = []
      for (let i = 0; i < this.layout.length; i++) {
        const l = this.layout[i]
        layout.push({
          x: l.x,
          y: l.y,
          w: l.w,
          h: l.h,
          i: l.i,
          d: l.d
        })
      }
      const content = {
        ...this.content,
        version: '1.0',
        widgets: this.widgets
      }
      const loading = this.$loading({
        lock: true,
        text: '正在保存',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.1)'
      });
      editBiDashboard({
        ...this.data,
        content: JSON.stringify(content)
      }).then(response => {
        this.change = false
        loading.close()
        this.$notify({
          title: '提示',
          message: '保存成功',
          type: 'success',
          duration: 1000
        });
      }).catch(e => {
        loading.close()
      })
    },

    getWidgetAbsPos(node) {
      const cls = node.getAttribute('class')
      if (cls && cls.indexOf('vue-grid-item') >= 0) {
        let transform = node.style.transform /// translate3d(1767px, 35px, 0px)
        transform = transform.replace('translate3d(', '')
        transform = transform.replace(')', '')
        const sps = transform.split(',')
        const left = parseFloat(sps[0].replace('px', ''))
        const top = parseFloat(sps[1].replace('px', ''))
        return {
          left: left,
          top: top,
          width: node.offsetWidth,
          height: node.offsetHeight
        }
      }
      if (node.parentNode) {
        return this.getWidgetAbsPos(node.parentNode)
      }
      return undefined
    },

    handleMessage(e1, e2, e3, e4, e5) {
      if (e1 == 'trigger-select' && e2 && e2.length > 0) {
        const pos = this.getWidgetAbsPos(e3.$el)
        if (pos) {
          const maskStyle = 'left: 0; top: 0; width: ' + this.$refs.gridLayout.$el.offsetWidth + 'px; height: ' + this.$refs.gridLayout.$el.offsetHeight + 'px;'
          let style = ''
          if (e3.settings && e3.settings.select && e3.settings.select.position && e3.settings.select.position.indexOf('right') >= 0) {
            style += 'right: ' + (this.$refs.gridLayout.$el.offsetWidth - pos.left - pos.width + (e3.settings && e3.settings.select && e3.settings.select.hoffset || 0)) + 'px;'
          } else {
            style += 'left: ' + (pos.left + (e3.settings && e3.settings.select && e3.settings.select.hoffset || 0)) + 'px;'
          }
          if (e3.settings && e3.settings.select && e3.settings.select.position && e3.settings.select.position.indexOf('top') >= 0) {
            style += 'bottom: ' + (this.$refs.gridLayout.$el.offsetHeight - pos.top + (e3.settings && e3.settings.select && e3.settings.select.voffset || 0)) + 'px;'
          } else {
            style += 'top: ' + (pos.top + pos.height + (e3.settings && e3.settings.select && e3.settings.select.voffset || 0)) + 'px;'
          }
          this.selectData = {
            maskStyle: maskStyle,
            style: style,
            theme: e3.settings && e3.settings.select && e3.settings.select.theme || 'light',
            data: e2, 
            widget: e3,
          }
        }
      } else if (e1 == 'open-url') {
        console.log(e2, e3)
        if (e2 == 'redirect') {
          window.location.href = e3
        } else if (e2 == 'dialog') {
          let maskStyle = ''
          let style = ''
          if (e4.settings && e4.settings.dialog && e4.settings.dialog.maskBackgroundColor) {
            maskStyle += 'background-color: ' + e4.settings.dialog.maskBackgroundColor + ';'
          }
          if (e4.settings && e4.settings.dialog && e4.settings.dialog.backgroundColor) {
            style += 'background-color: ' + e4.settings.dialog.backgroundColor + ';'
          }
          if (e4.settings && e4.settings.dialog && e4.settings.dialog.width) {
            style += 'width: ' + e4.settings.dialog.width + ';'
          } else {
            style += 'width: 80%;'
          }
          if (e4.settings && e4.settings.dialog && e4.settings.dialog.height) {
            style += 'height: ' + e4.settings.dialog.height + ';'
          } else {
            style += 'height: 80%;'
          }
          style += 'border-style: solid;'
          if (e4.settings && e4.settings.dialog && e4.settings.dialog.borderWidth) {
            style += 'border-width: ' + e4.settings.dialog.borderWidth + ';'
          }
          if (e4.settings && e4.settings.dialog && e4.settings.dialog.borderColor) {
            style += 'border-color: ' + e4.settings.dialog.borderColor + ';'
          }
          if (e4.settings && e4.settings.dialog && e4.settings.dialog.borderRadius) {
            style += 'border-radius: ' + e4.settings.dialog.borderRadius + '; overflow: hidden;'
          }

          let component = undefined
          let params = undefined
          if (e3.indexOf('component://') == 0) {
            const s = e3.substr('component://'.length)
            const sps = s.split('?')
            component = sps[0]
            if (sps.length > 1) {
              params = sps[1]
            }
          }
          console.log(e3, e3.indexOf('component://'), component, params)
          this.openUrlData = {
            maskStyle: maskStyle,
            style: style,
            component: component,
            params: params,
            url: e3
          }
        } else {
          window.open(e3)
        }
      } else if (e1 == 'show-popover-url') {
        //console.log(e2)
        let y = e2.event.event.clientY
        if (y + 230 > document.body.clientHeight) {
            y = document.body.clientHeight - 230
        }
        let maskStyle = 'pointer-events: none; left: ' + (e2.event.event.clientX + 10) + 'px; top: ' + (y) + 'px; overflow: hidden;'
        let style = 'width: 100%; height: 100%;'

        let component = undefined
        let params = undefined
        if (e3.indexOf('component://') == 0) {
          const s = e3.substr('component://'.length)
          const sps = s.split('?')
          component = sps[0]
          if (sps.length > 1) {
            params = sps[1]
          }
        }
        //console.log(e3, e3.indexOf('component://'), component, params)
        this.popoverUrlData = {
          maskStyle: maskStyle,
          style: style,
          component: component,
          params: params,
          url: e3
        }
      } else if (e1 == 'hide-popover-url') {
        this.popoverUrlData = null
      }
    },

    handleSelectChange(item) {
      this.selectData.widget.setSelectedItem(item)
      this.selectData = null
    },

    widgetContainerStyle(item) {
      let style = ''
      if (this.childrenDesign && this.childrenDesign.i != item.i) {
        style += 'opacity: 0.2;'
      } 
      
      if (this.readonly){
        if (item.d.settings.event && item.d.settings.event.pointerEvent) {
          style += 'pointer-events: all;'
        } else {
          style += 'pointer-events: none;'
        }
      }

      return style
    },

    widgetStyle(item) {
      let style = 'position: relative; display: flex; overflow: hidden; '

      if (!this.readonly && (!this.selectedItem || this.selectedItem.i != item.i) && this.mouseOverItem && this.mouseOverItem.i == item.i) {
        if (!this.childrenDesign || (item.pi && item.pi == this.childrenDesign.i)) {
          style += "border: dashed 1px #1890ff;outline: dashed 1px #fff;"
        }
      }

      if (!this.readonly) {
        if (item.pi && (!this.childrenDesign || this.childrenDesign.i != item.pi)) {
          style += 'pointer-events: none;'
        } else {
          style += 'pointer-events: all;'
        }
      } else {
        if (item.d.settings.event && item.d.settings.event.pointerEvent) {
          style += 'pointer-events: all;'
        } else {
          style += 'pointer-events: none;'
        }
      }

      return style
    },

    widgetInnerStyle(item) {
      let style = 'position: relative; display: flex; flex: 1;'

      if (!item.pi) {
        style += 'padding-left: ' + (item.d.settings.padding.left !== undefined ? item.d.settings.padding.left : this.settings.grid.padding) + 'px;'
        style += 'padding-right: ' + (item.d.settings.padding.right !== undefined ? item.d.settings.padding.right : this.settings.grid.padding) + 'px;'
        style += 'padding-top: ' + (item.h > 1 ? (item.d.settings.padding.top !== undefined ? item.d.settings.padding.top : this.settings.grid.padding) : 0) + 'px;'
        style += 'padding-bottom: ' + (item.h > 1 ? (item.d.settings.padding.bottom !== undefined ? item.d.settings.padding.bottom : this.settings.grid.padding) : 0) + 'px;'
      } else {
        for (let i = 0; i < this.layout.length; i++) {
          const l = this.layout[i]
          if (l.i == item.pi && l.settings) {
            style += 'padding-left: ' + (item.d.settings.padding.left !== undefined ? item.d.settings.padding.left : l.settings.grid.padding) + 'px;'
            style += 'padding-right: ' + (item.d.settings.padding.right !== undefined ? item.d.settings.padding.right : l.settings.grid.padding) + 'px;'
            style += 'padding-top: ' + (item.h > 1 ? (item.d.settings.padding.top !== undefined ? item.d.settings.padding.top : l.settings.grid.padding) : 0) + 'px;'
            style += 'padding-bottom: ' + (item.h > 1 ? (item.d.settings.padding.bottom !== undefined ? item.d.settings.padding.bottom : l.settings.grid.padding) : 0) + 'px;'
            break
          }
        }
      }

      const background = item.d.settings && item.d.settings.background
       
      if (background && background.color) {
        style += 'background-color: ' + background.color + ';'
      } else {
        if (!item.pi && this.settings.widget.backgroundColor) {
          style += 'background-color: ' + this.settings.widget.backgroundColor + ';'
        }
      }

      if (background && background.image) {
        style += 'background-image: url(' + background.image + ');background-repeat: no-repeat;'
        if (background.imageScale == 'auto') {
          style += 'background-size: 100% 100%;'
        } else if (background.imageScale == 'cover') {
          style += 'background-size: cover;'
        }
      }

      const border = item.d.settings && item.d.settings.border
      if (border) {
        const numReg = /^[0-9]+$/
        style += 'border-style: solid;'
        if (border.color) {
          style += 'border-color: ' + border.color + ';'
        }
        if (border.left) {
          if (numReg.test(border.left)) {
            style += 'border-left-width: ' + border.left + 'px;'
          } else {
            style += 'border-left-width: ' + border.left + ';'
          }
        } else {
          style += 'border-left-width: 0px;'
        }
        if (border.right) {
          if (numReg.test(border.right)) {
            style += 'border-right-width: ' + border.right + 'px;'
          } else {
            style += 'border-right-width: ' + border.right + ';'
          }
        } else {
          style += 'border-right-width: 0px;'
        }
        if (border.top) {
          if (numReg.test(border.top)) {
            style += 'border-top-width: ' + border.top + 'px;'
          } else {
            style += 'border-top-width: ' + border.top + ';'
          }
        } else {
          style += 'border-top-width: 0px;'
        }
        if (border.bottom) {
          if (numReg.test(border.bottom)) {
            style += 'border-bottom-width: ' + border.bottom + 'px;'
          } else {
            style += 'border-bottom-width: ' + border.bottom + ';'
          }
        } else {
          style += 'border-bottom-width: 0px;'
        }
        if (border.image) {
          style += 'border-image-source: url(' + border.image + ');'
        }
        if (border.imageSliceLeft || border.imageSliceRight || border.imageSliceTop || border.imageSliceBottom) {
          style += 'border-image-slice: ' + (border.imageSliceTop || border.imageSliceBottom || 0) + ' ' + (border.imageSliceRight || border.imageSliceLeft || 0) + ' ' + (border.imageSliceBottom || border.imageSliceTop || 0) + ' ' + (border.imageSliceLeft || border.imageSliceRight || 0) + ';'
        }
        if (border.imageRepeat) {
          style += 'border-image-repeat: ' + border.imageRepeat + ';'
        }
        if (border.radius) {
          if (numReg.test(border.radius)) {
            style += 'border-radius: ' + border.radius + 'px;'
          } else {
            style += 'border-radius: ' + border.radius + ';'
          }
        }
      }
      return style
    },

    widgetInnerInnerStyle(item) {
      let style = 'position: relative; overflow: hidden; display: flex; flex-direction: column; flex: 1;'
      const border = item.d.settings && item.d.settings.border
      if (border && (border.color || border.image)) {
        const numReg = /^[0-9]+$/
        if (border.left) {
          if (numReg.test(border.left)) {
            style += 'margin-left: -' + border.left + 'px;'
          } else {
            style += 'margin-left: -' + border.left + ';'
          }
        }
        if (border.right) {
          if (numReg.test(border.right)) {
            style += 'margin-right: -' + border.right + 'px;'
          } else {
            style += 'margin-right: -' + border.right + ';'
          }
        }
        if (border.top) {
          if (numReg.test(border.top)) {
            style += 'margin-top: -' + border.top + 'px;'
          } else {
            style += 'margin-top: -' + border.top + ';'
          }
        }
        if (border.bottom) {
          if (numReg.test(border.bottom)) {
            style += 'margin-bottom: -' + border.bottom + 'px;'
          } else {
            style += 'margin-bottom: -' + border.bottom + ';'
          }
        }
      }
      return style
    },

    titleAlign(align) {
      if (align == 'center') {
        return 'center'
      } else if (align == 'right') {
        return 'flex-end'
      } else {
        return 'flex-start'
      }
    },

    widgetTitleStyle(item) {
      if (!item.d.settings || !item.d.settings.title) {
        return ''
      }

      const title = item.d.settings.title
      let style = 'justify-content: ' + this.titleAlign(title.align) + '; font-size: ' + (title.fontSize || this.settings.widget.titleFontSize || 18) + 'px; color: ' + (title.color || this.settings.widget.titleColor || '#000') + '; ' + (title.hidden ? 'display: none;' : '')
      if (title.bold) {
          style += 'font-weight: bold;';
      } else {
          style += 'font-weight: normal;';
      }
      if (title.showSplitLine) {
        style += "padding-bottom: " + (title.splitLineMargin || 10) + "px; border-bottom: " + (title.splitLineStyle || 'solid') + " " + (title.splitLineSize || 1) + "px " + (title.splitLineColor || '#eee') + ";"
      }
      if (item.h == 1) {
        style += "margin-bottom: 0px; align-items: center; flex: 1;"
      }

      return style
    },

    itemControlClass(item) {
      let cls = 'item-control-container '
      if (item.x == 0 && item.x + item.w >= this.colNum) {
         cls += 'item-control-container-inner'
      } else {
        if (item.x + item.w >= this.colNum) {
          cls += 'item-control-container-left'
        } else {
          cls += 'item-control-container-right'
        }
      }
      return cls
    }
  },
  computed: {
    layoutBackgroundStyle() {
      let style = 'display: flex;'
      if (this.settings.dashboard.backgroundColor) {
        style += 'background-color: ' + this.settings.dashboard.backgroundColor + ';'
      }
      if (this.settings.dashboard.backgroundImage) {
        style += 'background-image: url(' + this.settings.dashboard.backgroundImage + ');background-repeat: no-repeat;'
        if (this.settings.dashboard.backgroundImageWidth || this.settings.dashboard.backgroundImageHeight) {
          const numReg = /^[0-9]+$/
          if (this.settings.dashboard.backgroundImageWidth && this.settings.dashboard.backgroundImageHeight) {
            style += 'background-size: ' + this.settings.dashboard.backgroundImageWidth + (numReg.test(this.settings.dashboard.backgroundImageWidth) ? 'px' : '') + ' ' + this.settings.dashboard.backgroundImageHeight + (numReg.test(this.settings.dashboard.backgroundImageHeight) ? 'px' : '') + ';'
          } else if (this.settings.dashboard.backgroundImageWidth) {
            style += 'background-size: ' + this.settings.dashboard.backgroundImageWidth + (numReg.test(this.settings.dashboard.backgroundImageWidth) ? 'px' : '') + ' auto;'
          } else if (this.settings.dashboard.backgroundImageHeight) {
            style += 'background-size: auto ' + this.settings.dashboard.backgroundImageHeight + (numReg.test(this.settings.dashboard.backgroundImageHeight) ? 'px' : '') + ';'
          }
          style += 'background-position: center top;'
        } else {
          if (this.settings.dashboard.backgroundImageScale == 'auto') {
            style += 'background-size: 100% 100%;'
          } else if (this.settings.dashboard.backgroundImageScale == 'cover') {
            style += 'background-size: cover;'
          }
        }
      }
      if (this.layoutType == 'pc') {
        style += 'flex: 1;'
      } else {
        style += 'width: ' + MOBILE_WIDTH + 'px; height: ' + MOBILE_HEIGHT + 'px;'
      }
      return style
    },
    layoutContainerStyle() {
      let style = 'flex: 1;'
      if (this.settings.dashboard.backgroundImageMaskColor) {
        style += 'background-color: ' + this.settings.dashboard.backgroundImageMaskColor + ';'
      }
      return style
    }
  },
  watch: {
    $route(to, from) {
      if (to.path == from.path) {
        if (to.query['_'] != from.query['_']) {
          const id = to.query['_']
          this.init(id)
        } else {
          let change = false
          const changeParams = {}
          for (let key in to.query) {
            if (to.query[key] != from.query[key]) {
              changeParams[key] = to.query[key]
              change = true
            }
          }
          for (let key in from.query) {
            if (to.query[key] != from.query[key]) {
              changeParams[key] = to.query[key]
              change = true
            }
          }
          if (change) {
            this.loadModelDataIfNeeded({}, changeParams)
          }
        }
      }
    }
  }
};
</script>

<style scoped>
.tabs-body::-webkit-scrollbar {
  width:8px;
  background-color: #fff;
}
.tabs-body::-webkit-scrollbar-track {
  -webkit-box-shadow:inset006pxrgba(0,0,0,0.3);
  border-radius:8px;
}
.tabs-body::-webkit-scrollbar-thumb {
  border-radius:8px;
  background:rgba(0,0,0,0.1);
  -webkit-box-shadow:inset006pxrgba(0,0,0,0.5);
}
.tabs-body::-webkit-scrollbar-thumb:window-inactive {
  background:rgba(0,0,0,0.1);
}
.tabs-body::-webkit-scrollbar-thumb:hover {
  background:rgba(0,0,0,0.2);
}
#top-bar {
  height: 40px;
  background-color: #fff;
  border-bottom: 1px solid #f5f5f5;
  padding: 0px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
#dashbard-design-container {
  display: flex;
}
#left-bar {
  width: 220px;
  border-right: 1px solid #f5f5f5;
  overflow: auto;
}
.tabs {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  flex: 1;
}
.tabs-header {
  height: 40px;
  background-color: #fff;
  display: flex;
}
.tabs-header-item {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 0px;
  font-size: 13px;
  color: #333;
  font-weight: bold;
  cursor: pointer;
}
.tabs-header-item-active {
  background-color: #fff;
  border-bottom: solid 2px #1890ff;
  border-top: solid 2px #fff;
  color: #1890ff;
}
.tabs-header-item:hover {
  color: #1890ff;
}
.tabs-body {
  overflow: auto;
}
.tabs-panel {
  height: 100%;
  /*padding: 15px;*/
}
.collapse-title {
  margin-left: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.collapse-content {
  padding-left: 10px;
  padding-right: 10px;
}
.tree-node {
  font-size: 14px;
}
.tree-node-leaf {
  /*-webkit-user-drag: element;
  user-select: none;*/
}
.tree-node-root {
  /*user-select: none;*/
}
.custom-tree-node {
  width: 100%;
  display: flex;
  align-items: center;
  height: 26px;
  padding-right: 5px;
}
.custom-tree-node-img {
  width:20px; 
  height:20px; 
  margin-right:6px
}
.custom-tree-node-label {
  flex: 1;
  width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
}
.custom-tree-node-button {
  display: none;
}
.custom-tree-node:hover .custom-tree-node-button {
  display: inline-block;
}
#dashbard-layout {
  flex: 1;
  display: flex;
  justify-content: center;
  overflow: auto;
  background-color: #eee;
}
#dashbard-layout-container {
  overflow: auto;
  position: relative;
}
#dashbard-layout-container::-webkit-scrollbar {
  display: none;
}
.vue-grid-layout {
  /*background: #f5f5f5;*/
}
.vue-grid-item:not(.vue-grid-placeholder) {
  /*background: #fff;*/
}
.vue-grid-item .resizing {
  opacity: 0.9;
}
.vue-grid-item .static {
  background: #cce;
}
.vue-grid-item .item-container {
  border: 1px solid rgba(0,0,0,0);
  font-size: 18px;
  text-align: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  height: 100%;
  width: 100%;
  display: flex;
}
.vue-grid-item .sitem-container {
  border: 1px solid rgba(0,0,0,0);
  font-size: 18px;
  text-align: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  height: 100%;
  width: 100%;
  display: flex;
}
.item-title {
  display: flex;
  margin-bottom: 8px;
}
.item-title div {
  cursor: default;
}
.item-body {
  flex: 1;
  position: relative;
  overflow: hidden;
}
.vue-grid-item .item-normal:hover {
  border: 1px dashed #1890ff;
  outline: 1px dashed #fff;
}
.vue-grid-item .item-selected {
  border: 1px solid #1890ff;
  outline: 1px solid #fff;
  z-index: 100;
}
.vue-grid-item .sitem-normal {
  border: 1px dashed #ccc;
}
.vue-grid-item .sitem-normal:hover {
  border: 1px dashed #1890ff;
  outline: 1px dashed #fff;
}
.vue-grid-item .sitem-selected {
  border: 1px solid #1890ff;
  outline: 1px solid #fff;
  z-index: 100;
}
.vue-grid-item .item-drag {
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  /*overflow: hidden;*/
}
.vue-grid-item .item-no-drag {
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  /*overflow: hidden;*/
}
.vue-grid-item .sitem-drag {
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  /*overflow: hidden;*/
}
.vue-grid-item .sitem-no-drag {
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  /*overflow: hidden;*/
}
.vue-grid-item .minMax {
  font-size: 12px;
}
.vue-grid-item .add {
  cursor: pointer;
}
.vue-grid-item-selected {
  z-index: 1;
}
.item-control-container {
  z-index: 200;
  position: absolute; 
  top: 0px; 
  width: 24px; 
  min-height: 56px; 
  background-color: #fff;
  cursor: default;
  border-top: 1px solid #1890ff;
  border-bottom: 1px solid #1890ff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.item-control-container-left {
  left: -23px; 
  border-left: 1px solid #1890ff;
  border-right: 1px solid #ddd;
}
.item-control-container-right {
  right: -23px; 
  border-left: 1px solid #ddd;
  border-right: 1px solid #1890ff;
}
.item-control-container-inner {
  left: 0px; 
  border-left: 1px solid #1890ff;
  border-right: 1px solid #1890ff;
}
.vue-grid-item-normal .item-control-container {
  display: none;
}
vue-grid-item-selected .item-control-container {
  display: block;
}
.item-control {
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.item-control i {
  font-size: 15px;
  color: #1890ff;
}
.item-control i:hover {
  cursor: pointer;
}
.select-mask {
  z-index: 299; 
  position: absolute; 
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.select-container {
  position: absolute; 
  min-width: 60px; 
  max-width: 200px;
  min-height: 42px; 
  max-height: 242px;
  overflow-y: auto;
  overflow-x: hidden;
}
.select-container-light {
  background-color: rgba(255, 255, 255, 0.9);
  border: solid 1px rgba(0, 0, 0, 0.05);
}
.select-container-dark {
  background-color: rgba(40, 40, 40, 0.9);
  border: solid 1px rgba(0, 0, 0, 0.05);
  color: #fff;
}
.select-item {
  height: 40px;
  line-height: 40px;
  padding: 0px 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border-bottom: solid 1px rgba(0, 0, 0, 0.05);
}
.select-item-light:hover {
  cursor: pointer;
  background-color: rgba(24, 144, 255, 0.8);
  color: #fff;
}
.select-item-dark:hover {
  cursor: pointer;
  background-color: rgba(64, 64, 64, 0.8);
  color: #fff;
}
.select-container-light::-webkit-scrollbar {
  width:8px;
  background-color: rgba(255, 255, 255, 0.9);
}
.select-container-light::-webkit-scrollbar-track {
  -webkit-box-shadow:inset006pxrgba(0,0,0,0.3);
  border-radius:8px;
}
.select-container-light::-webkit-scrollbar-thumb {
  border-radius:8px;
  background:rgba(0,0,0,0.1);
  -webkit-box-shadow:inset006pxrgba(0,0,0,0.5);
}
.select-container-light::-webkit-scrollbar-thumb:window-inactive {
  background:rgba(0,0,0,0.1);
}
.select-container-light::-webkit-scrollbar-thumb:hover {
  background:rgba(0,0,0,0.2);
}
.select-container-dark::-webkit-scrollbar {
  width:8px;
  background-color: rgba(40, 40, 40, 0.9);
}
.select-container-dark::-webkit-scrollbar-track {
  -webkit-box-shadow:inset006pxrgba(0,0,0,0.3);
  border-radius:8px;
}
.select-container-dark::-webkit-scrollbar-thumb {
  border-radius:8px;
  background:rgba(255,255,255,0.1);
  -webkit-box-shadow:inset006pxrgba(0,0,0,0.5);
}
.select-container-dark::-webkit-scrollbar-thumb:window-inactive {
  background:rgba(255,255,255,0.1);
}
.select-container-dark::-webkit-scrollbar-thumb:hover {
  background:rgba(255,255,255,0.2);
}
.dialog-mask {
  z-index: 299; 
  position: absolute; 
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>

<style>
.vue-grid-item.vue-grid-placeholder {
  background: #666 !important;
}
.vue-grid-item>.vue-resizable-handle {
  z-index: 101;
  /*display: none;*/
}
.el-tree-node.is-current > .el-tree-node__content {
    background: rgba(22, 119, 255, 0.1);
    /*border-right: 3px solid #1677ff;*/
    color: #187aff;
}
</style>